import React, { FC, useCallback, useState } from 'react';
import { Bot } from '../../../models/Bots';
import { ProfileInfoHover } from '../../bots/ProfileInfoHover';
import { Loading, LoadingSize } from '../../controls/Loading/Loading';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

interface BotsSelectProps {
  bots: Bot[];
  botsLoading: boolean;
  setSelectedBot: (bot: Bot) => void;
}

const BotsSelectDashAi: FC<BotsSelectProps> = ({
  bots,
  botsLoading,
  setSelectedBot,
}) => {
  return (
    <div className="max-h-[300px] overflow-y-auto cursor-auto">
      {botsLoading ? (
        <Loading size={LoadingSize.Small} />
      ) : // eslint-disable-next-line unicorn/no-nested-ternary
      bots.length > 0 ? (
        bots.map((bot) => {
          return (
            <div
              className="flex items-center px-3 py-2 gap-2 hover:bg-cloud-10 cursor-pointer"
              key={bot.id}
              onClick={() => {
                setSelectedBot(bot);
              }}
            >
              <span className="text-xl/5">{bot.icon}</span>
              {bot.bot_name}
              {bot.description && (
                <ProfileInfoHover
                  description={bot.description}
                  icon={bot.icon}
                  name={bot.bot_name}
                />
              )}
            </div>
          );
        })
      ) : (
        <div className="text-gray-50 text-sm text-center">No bots found</div>
      )}
    </div>
  );
};

interface BotsSelectButtonProps {
  disabled?: boolean;
  bots: Bot[];
  botsLoading: boolean;
  setSelectedBot: (bot: Bot) => void;
}

export const BotsSelectButtonDashAi: FC<BotsSelectButtonProps> = ({
  disabled,
  bots,
  botsLoading,
  setSelectedBot,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  const handleSelectBot = useCallback(
    (bot: Bot) => {
      setSelectedBot(bot);
      setOpenSelect(false);
    },
    [setSelectedBot, setOpenSelect]
  );

  const handleOpenSelect = useCallback(() => {
    if (disabled) {
      setOpenSelect(false);
      return;
    }

    setOpenSelect((prev) => !prev);
  }, [disabled, setOpenSelect]);

  return (
    <Popover onOpenChange={handleOpenSelect} open={openSelect}>
      <PopoverTrigger asChild disabled={disabled}>
        <div>
          <UITooltip title="Select Bot">
            <div
              className={`flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border ${
                disabled ? 'opacity-50' : ''
              }`}
            >
              <UIIcon name="at-sign" size={16} stroke />
            </div>
          </UITooltip>
        </div>
      </PopoverTrigger>
      <PopoverContent align="center" className="p-0">
        <BotsSelectDashAi
          bots={bots}
          botsLoading={botsLoading}
          setSelectedBot={handleSelectBot}
        />
      </PopoverContent>
    </Popover>
  );
};
