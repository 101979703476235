import { BotMinimal } from '../../models/Bots';
import { uniqBy } from '../../scripts/utils';
import { Action } from '../actions';
import { GlobalState } from '../state';
import { BotsMinimalAction } from './actions';

export const botsMinimalReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  const botsMinimalState = { ...state.botsMinimal };

  switch (action.type) {
    case BotsMinimalAction.ADD_BOTS: {
      const newlyAddedBots = action.payload as BotMinimal[];

      botsMinimalState.bots = uniqBy(
        [...botsMinimalState.bots, ...newlyAddedBots],
        'id'
      );

      break;
    }

    case BotsMinimalAction.ADD_SEARCH_QUERY: {
      const addedQuery = action.payload as string;

      if (!botsMinimalState.searchedQueries.includes(addedQuery)) {
        botsMinimalState.searchedQueries = [
          ...botsMinimalState.searchedQueries,
          addedQuery,
        ];
      }

      break;
    }
  }

  return {
    ...state,
    botsMinimal: botsMinimalState,
  };
};
