import { QATopic } from './QAmodels';

export interface SharedConversationsSubscriptionResponse {
  conversationMemberships: {
    nodes: {
      conversation: ConversationResponse;
    }[];
  };
}

export interface ManagedBotsConversationsSubscriptionResponse {
  botsAdminsByAdminId: {
    nodes: {
      botId: string;
      adminId: string;
      bot: {
        id: string;
        botName: string;
        isManaged: boolean;
        botsConversations: {
          nodes: {
            conversationId: string;
            createdAt: number;
            conversation: ConversationResponse;
          }[];
        };
      };
    }[];
  };
}

export interface ConversationResponse {
  conversationId: string;
  summary: string | null;
  archived: boolean;
  createdAt: number;
  messageUserInteractions: {
    totalCount: number;
  };
}

export const processConversationResponse = (
  conversation: ConversationResponse
): QATopic => {
  return {
    topicId: conversation.conversationId,
    topicTitle: conversation.summary!,
    createdAt: conversation.createdAt,
    unreadCount: conversation.messageUserInteractions.totalCount,
  };
};
