// Connection status codes
export const enum AppState {
  NotConnected = 0,
  Connecting = 1,
  Disconnecting = -1,
  ErrorReconnect = -2,
  ErrorDoNotReconnect = -3,
  Indexing = 2,
  IndexingComplete = 3,
  IndexingFailed = 4,
  PartiallyConnected = 5,
}

export const filterableIndexStates = new Set<AppState | undefined>([
  AppState.Connecting,
  AppState.ErrorReconnect,
  AppState.Indexing,
  AppState.IndexingComplete,
  AppState.IndexingFailed,
]);

export const pendingAppStates = new Set<AppState>([
  AppState.Connecting,
  AppState.Indexing,
]);

export const errorAppStates = new Set<AppState>([
  AppState.ErrorReconnect,
  AppState.IndexingFailed,
]);

/* eslint-disable @cspell/spellchecker */
export const stagingExtensionId = 'pacijebmofcpggdepjmpapdkmoohbhbc';
export const productionExtensionId = 'iecgdccnjbljdabegnogafjkkjlcpcgh';

export const TOPIC_LINK_COPIED_SUCCESS_MESSAGE = 'Copied link to clipboard';
export const TOPIC_LINK_COPIED_FAILURE_MESSAGE = 'Failed to copy link';
