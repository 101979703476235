import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PaywallModal } from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { UserImage } from '../../components/controls/UserImage/UserImage';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../components/controls/ui/UIToolTip/UIToolTip';
import { QAFeedbackModal } from '../../components/jit-qa/QAFeedbackModal';
import { QAHeaderConnectApps } from '../../components/jit-qa/QAHeaderConnectApps';
import { QAMessageView } from '../../components/jit-qa/QAMessageView';
import { QAPageAnswerView } from '../../components/jit-qa/QAPageView';
import { QAStreamingMessage } from '../../components/jit-qa/QAStreamingMessage';
import { QAReferencesSidecar } from '../../components/jit-qa/references/QAReferencesSidecar/QAReferencesSidecar';
import { QATextInputView } from '../../components/jit-qa/textInputBox/QATextInputView';
import { QATopicDivider } from '../../components/jit-qa/topicDivider/QATopicDivider';
import { LoadingSplash } from '../../components/splash/LoadingSplash';
import { QAWorkflowTemplateModal } from '../../components/workflowModal/QAWorkflowTemplateModal';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { DEFAULT_TOPIC_ACCESS, QAMessage } from '../../models/QAmodels';
import { SocialAppUsers } from '../../models/User';
import { useQAController } from '../../scripts/QAController';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState, useUserSafe } from '../../scripts/hooks';
import { useBots } from '../../scripts/hooks/bots';
import { logDebug } from '../../scripts/utils';

interface Author {
  displayName?: string;
  email: string;
  icon?: string;
}

export const TopicPage: FC = () => {
  const { id, msgId } = useParams<{ id: string; msgId?: string }>();

  const [author, setAuthor] = React.useState<Author>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const user = useUserSafe();

  const qaController = useQAController();
  const { bots } = useBots();

  const messages = qaController.useCurrentTopicMessages();

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const {
          author: responseAuthor,
          messages: responseMessages,
          topic_files: responseTopicFiles,
        } = await invokeFastApi<{
          author: Author;
          messages: QAMessage[];
          topic_files: string[];
        }>({
          path: `/topics/topicById/${id}`,
          method: 'GET',
        });

        qaController.setCurrentTopicMessages(responseMessages);
        qaController.setTopicFiles(id, responseTopicFiles);
        qaController.markTopicAsRead(id);
        setAuthor(responseAuthor);
      } catch (error) {
        logDebug(error);
        history.push(SidebarRoutes.JitQA);
      }

      setLoading(false);
    };

    fetchMessages();
    qaController.setIsNewTopic(false);
  }, [id, history, qaController]);

  useEffect(() => {
    if (!author) return;
    if (user.email !== author.email) {
      trackEvent(AnalyticsEvent.SharableLinkViewed);
    }
  }, [author, user]);

  const { topicTitle, topicTimestamp, topicAccess } = useMemo(() => {
    if (messages[0] && messages[0].conversation_id === id) {
      return {
        topicTitle: messages[0].topic_title,
        topicTimestamp: messages[0].conversation_timestamp,
        topicAccess: messages[0].topicAccess,
      };
    }

    return {
      topicTitle: '',
      topicTimestamp: 0,
      topicAccess: {
        isMultiplayer: false,
        isOrgWide: false,
        isPrivate: false,
      },
    };
  }, [messages, id]);

  if (loading) {
    return (
      <div className="flex h-screen">
        <LoadingSplash />
      </div>
    );
  }

  const isTopicEditable = topicAccess?.isMultiplayer
    ? true
    : author?.email === user.email;

  return (
    <div className="flex h-screen">
      <div className="flex flex-1 flex-col h-full">
        <div className="flex-grow overflow-auto scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin">
          <QATopicDivider
            conversationId={id}
            isDeletable={author?.email === user.email}
            isEditable={isTopicEditable}
            isNewTopic={false}
            messages={messages}
            topicAccess={topicAccess ?? DEFAULT_TOPIC_ACCESS}
            topicTimestamp={topicTimestamp}
            topicTitle={topicTitle}
          />
          <div className="hidden lg:block">
            <QAHeaderConnectApps />
          </div>
          <div className="max-w-3xl mx-auto mb-8 mt-2 w-[100vw]">
            <div>
              {messages.map((message) => (
                <QAMessageView
                  bots={bots}
                  isFocused={message.message_id === msgId}
                  key={message.row_id}
                  qaMessage={message}
                  sharableConversation={messages}
                  showAllSources={message.author?.email === user.email}
                />
              ))}
              <QAStreamingMessage
                bots={bots}
                showAllSources={author?.email === user.email}
                topic_id={id}
              />
            </div>
          </div>
          {!isTopicEditable && <MarketingMaterial />}
        </div>
        {isTopicEditable && (
          <div className="sticky bottom-0 bg-white">
            <QATextInputView
              autofocus={false}
              continueConversationId={id}
              setOpenPaywallModal={setOpenPaywallModal}
            />
          </div>
        )}
        <QAPageAnswerView />
        <QAWorkflowTemplateModal setOpenPaywallModal={setOpenPaywallModal} />
        <QAFeedbackModal sharableConversation={messages} />
      </div>
      <QAReferencesSidecar />

      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};

const MarketingMaterial = () => {
  const user = useUserSafe();

  const history = useHistory();

  const teamMates = useMemo(() => {
    const seen = new Set();

    return user.orgByOrgId.appPreferences.recommended_apps_list?.reduce(
      (acc: SocialAppUsers[], app) => {
        if (app.social_info_list.length > 0) {
          for (const socialInfo of app.social_info_list) {
            if (acc.length > 5) continue;
            if (seen.has(socialInfo.email)) continue;
            seen.add(socialInfo.email);
            acc.push(socialInfo);
          }
        }

        return acc;
      },
      []
    );
  }, [user]);

  return (
    <div>
      <div className="sm:mx-16 border-b" />
      <div className="mt-7 mx-auto max-w-3xl px-2 sm:px:0">
        <div className="flex items-center justify-between rounded neumorphic-background border border-gray-20 border-solid p-5">
          <div>Try Dash AI and get answers to all your work questions</div>
          <UIButton
            onClick={() => {
              history.push(SidebarRoutes.JitQA);
            }}
          >
            Start a chat
          </UIButton>
        </div>
        <div className="flex sm:flex-row flex-col gap-8 justify-center mb-8 mt-6">
          <div>
            <div className="font-medium">
              Team members using Dash AI as their Knowledge Assistant.
            </div>
            <div className="flex mt-6">
              {teamMates?.map((teamMate) => (
                <div className="-mr-3" key={teamMate.email}>
                  <UITooltip title={teamMate.email}>
                    <div>
                      <UserImage
                        displayName={teamMate.name}
                        imageUrl={teamMate.icon}
                        size={48}
                      />
                    </div>
                  </UITooltip>
                </div>
              ))}
            </div>
          </div>
          <div className="border-r border-l-0 border-b-0 border-gray-20 border-solid" />

          <div>
            <div className="font-medium">
              One assistant for all your use cases at work.
            </div>
            <div className="grid grid-cols-2 gap-2 mt-6">
              <div className="flex gap-2 items-center bg-white rounded-2xl">
                <UIIcon name="slack" size={18} type="apps" />
                <div className="text-xs">Summary of this thread</div>
              </div>
              <div className="flex gap-2 items-center bg-white rounded-2xl">
                <UIIcon name="jira" size={18} type="apps" />
                <div className="text-xs">Who is assigned a ticket</div>
              </div>
              <div className="flex gap-2 items-center bg-white rounded-2xl">
                <UIIcon name="notion" size={18} type="apps" />
                <div className="text-xs">Find me the latest standup doc</div>
              </div>
              <div className="flex gap-2 items-center bg-white rounded-2xl">
                <UIIcon name="github" size={18} type="apps" />
                <div className="text-xs">Explain the code of this file</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
