/* eslint-disable @cspell/spellchecker */
import './MarkdownParser.scss';
import React, { FC, useCallback } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { trackEvent } from '../../extra/sharedMethods';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useToaster } from '../../scripts/hooks';
import { stripReferences } from '../../scripts/utils';
import { UIIconButton } from '../controls/ui/UIIconButton/UIIconButton';

export const MarkdownParser: FC<{
  text: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customAnchorElement?: React.ComponentType<any>;
  customParagraphElement?: FC<ReactMarkdownProps>;
  pluginsDisabled?: boolean;
}> = ({
  text,
  className,
  customAnchorElement,
  customParagraphElement,
  pluginsDisabled = false,
}) => {
  const components: Components = { code: CodeBlock, table: Table };
  if (customAnchorElement) components.a = customAnchorElement;
  if (customParagraphElement) components.p = customParagraphElement;

  return (
    <div className="markdownParser">
      <ReactMarkdown
        className={className}
        components={components}
        linkTarget="_blank"
        rehypePlugins={pluginsDisabled ? [] : [rehypeRaw]}
        remarkPlugins={pluginsDisabled ? [] : [remarkGfm]}
      >
        {text}
      </ReactMarkdown>
    </div>
  );
};

const CodeBlock: FC<CodeProps> = ({
  inline,
  className,
  children,
  ...props
}) => {
  const match = /language-(\w+)/.exec(className ?? '');
  const language = match?.[1] ?? '';

  const toaster = useToaster();

  const handleCopy = useCallback(() => {
    const cleanedCode = stripReferences(String(children));
    navigator.clipboard.writeText(cleanedCode);

    trackEvent(AnalyticsEvent.QAMessageResponseUserCopiedCode);
    toaster.success('Copied text to clipboard');
  }, [children, toaster]);

  if (inline) {
    return (
      <code {...props} className={className}>
        {children}
      </code>
    );
  }

  return (
    <div className="relative">
      <SyntaxHighlighter
        {...props}
        CodeTag="div"
        PreTag="div"
        codeTagProps={{ className: 'codeBlock' }}
        customStyle={{
          padding: '8px 16px',
          borderRadius: '8px',
        }}
        language={language}
        style={stackoverflowDark}
        wrapLongLines
      >
        {stripReferences(String(children))}
      </SyntaxHighlighter>
      <div className="absolute top-2 right-2 text-white">
        <UIIconButton
          className="focus:outline-none"
          name="copy"
          onClick={handleCopy}
        />
      </div>
    </div>
  );
};

const Table: FC<ReactMarkdownProps> = ({ children }) => {
  return (
    <div className="markdownTable">
      <table>{children}</table>
    </div>
  );
};
