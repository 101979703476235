import classNames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  count: number;
}

export const NotificationsBadge = ({
  count,
  ...props
}: Props): JSX.Element | null => {
  if (count === 0) {
    return null;
  }

  return (
    <div
      {...props}
      className={classNames(
        'bg-mahogany-20 w-5 h-4 rounded-[60px] text-center text-white text-xs flex items-center justify-center',
        props.className
      )}
    >
      {count > 9 ? (
        <>
          9 <span className="text-2xs">+</span>
        </>
      ) : (
        count
      )}
    </div>
  );
};
