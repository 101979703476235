import { useCallback, useEffect, useState } from 'react';
import { BotMinimal } from '../../models/Bots';
import {
  addBotsMinimal,
  addBotsMinimalSearchedQuery,
} from '../../redux/botsMinimal/actions';
import { useDispatch } from '../../redux/store';
import { invokeFastApi } from '../apis/fastapi';
import { logError, uniqBy } from '../utils';
import { useGlobalState } from './redux';

interface BotsMinimalResponse {
  bots: BotMinimal[];
  allBots: BotMinimal[];
  loading: boolean;
  fetchBotsByQuery: (q: string) => Promise<BotMinimal[]>;
}

const filterBotsByQuery = (bots: BotMinimal[], query: string): BotMinimal[] => {
  return bots.filter((bot) =>
    bot.bot_name.toLowerCase().includes(query.toLowerCase())
  );
};

export const useBotsMinimal = (): BotsMinimalResponse => {
  const dispatch = useDispatch();
  const { bots: allBots, searchedQueries } = useGlobalState(
    (state) => state.botsMinimal
  );

  const [bots, setBots] = useState<BotMinimal[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchBotsByQuery = useCallback(
    async (q: string): Promise<BotMinimal[]> => {
      if (searchedQueries.includes(q)) {
        const filteredBots = filterBotsByQuery(allBots, q);
        setBots(filteredBots);
        return filteredBots;
      }

      try {
        setLoading(true);

        const { bots: searchedBots } = await invokeFastApi<{
          bots: BotMinimal[];
        }>({
          path: '/bots',
          method: 'GET',
          queryParams: { q, minimal: 'True' },
        });

        const allMergedBots = uniqBy([...allBots, ...searchedBots], 'id');

        dispatch(addBotsMinimal(searchedBots));
        dispatch(addBotsMinimalSearchedQuery(q));

        const filteredBots = filterBotsByQuery(allMergedBots, q);
        setBots(filteredBots);
        setLoading(false);
        return filteredBots;
      } catch (error) {
        logError(error);
        setLoading(false);
      }

      const filteredBots = filterBotsByQuery(allBots, q);
      setBots(filteredBots);
      return filteredBots;
    },
    [allBots, dispatch, searchedQueries]
  );

  useEffect(() => {
    fetchBotsByQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    bots,
    allBots,
    loading,
    fetchBotsByQuery,
  };
};
