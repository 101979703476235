import { App } from '../scripts/app';
import { FeatureFlags } from '../scripts/hooks';
import { AirtableApp } from './airtable/airtable';
import { AsanaApp } from './asana/asana';
import { AshbyApp } from './ashby/ashby';
import { AttioApp } from './attio/attio';
import { BambooHrApp } from './bamboo_hr/bamboo_hr';
import { BitbucketApp } from './bitbucket/bitbucket';
import { BrandfolderApp } from './brandfolder/brandfolder';
import { ClickupApp } from './clickup/clickup';
import { CodaApp } from './coda/coda';
import { ConfluenceApp } from './confluence/confluence';
import { CustomApiApp } from './custom-api/custom-api';
import { DarwinboxApp } from './darwinbox/darwinbox';
import { AppName, appsDefinitionFactory } from './definition';
import { DropboxApp } from './dropbox/dropbox';
import { FigmaApp } from './figma/figma';
import { FreshdeskApp } from './freshdesk/freshdesk';
import { GoogleCalendarApp } from './gcal/gcal';
import { GoogleDriveApp } from './gdrive/gdrive';
import { GitbookApp } from './gitbook/gitbook';
import { GithubApp } from './github/github';
import { GitlabApp } from './gitlab/gitlab';
import { GmailApp } from './gmail/gmail';
import { GongApp } from './gong/gong';
import { GoogleSitesApp } from './gsites/gsites';
import { GuruApp } from './guru/guru';
import { GustoApp } from './gusto/gusto';
import { GoogleDirectorySyncApp } from './gworkspace-directory/GoogleDirectorySyncApp';
import { HelpjuiceApp } from './helpjuice/helpjuice';
import { HelpscoutApp } from './helpscout/helpscout';
import { JiraApp } from './jira/jira';
import { MiroApp } from './miro/miro';
import { MondayApp } from './monday/monday';
import { MSTeamsApp } from './msteams/msteams';
import { NotionApp } from './notion/notion';
import { OnedriveApp } from './onedrive/onedrive';
import { OutlookApp } from './outlook/outlook';
import { PaylocityApp } from './paylocity/paylocity';
import { QuipApp } from './quip/quip';
import { ReadmeApp } from './readme/readme';
import { RipplingApp } from './rippling/rippling';
import { SalesforceApp } from './salesforce/salesforce';
import { ServiceNowApp } from './servicenow/servicenow';
import { SharepointApp } from './sharepoint/sharepoint';
import { ShortcutApp } from './shortcut/shortcut';
import { SlabApp } from './slab/slab';
import { SlackApp } from './slack/slack';
import { StackOverflowTeamsApp } from './stackoverflowteams/stackoverflowteams';
import { WebsiteApp } from './website/website';
import { WorkdayApp } from './workday/workday';
import { ZendeskApp } from './zendesk/zendesk';

export const ctorMap = new Map<AppName, typeof App>([
  ['aha', App],
  ['airtable', AirtableApp],
  ['asana', AsanaApp],
  ['ashby', AshbyApp],
  ['attio', AttioApp],
  ['bamboo_hr', BambooHrApp],
  ['bitbucket', BitbucketApp],
  ['box', App],
  ['brandfolder', BrandfolderApp],
  ['canva', App],
  ['clickup', ClickupApp],
  ['coda', CodaApp],
  ['confluence', ConfluenceApp],
  ['custom-api', CustomApiApp],
  ['darwinbox', DarwinboxApp],
  ['docusign', App],
  ['dropbox', DropboxApp],
  ['figma', FigmaApp],
  ['freshdesk', FreshdeskApp],
  ['front', App],
  ['gcal', GoogleCalendarApp],
  ['gdrive', GoogleDriveApp],
  ['gitbook', GitbookApp],
  ['github', GithubApp],
  ['gitlab', GitlabApp],
  ['gmail', GmailApp],
  ['gong', GongApp],
  ['gsites', GoogleSitesApp],
  ['guru', GuruApp],
  ['gusto', GustoApp],
  ['gworkspace-directory', GoogleDirectorySyncApp],
  ['helpjuice', HelpjuiceApp],
  ['helpscout', HelpscoutApp],
  ['hubspot', App],
  ['intercom', App],
  ['jira', JiraApp],
  ['linear', App],
  ['lucidchart', App],
  ['mailchimp', App],
  ['mural', App],
  ['miro', MiroApp],
  ['monday', MondayApp],
  ['msteams', MSTeamsApp],
  ['notion', NotionApp],
  ['onedrive', OnedriveApp],
  ['outlook', OutlookApp],
  ['paylocity', PaylocityApp],
  ['pipedrive', App],
  ['productboard', App],
  ['quip', QuipApp],
  ['readme', ReadmeApp],
  ['rippling', RipplingApp],
  ['salesforce', SalesforceApp],
  ['servicenow', ServiceNowApp],
  ['sharepoint', SharepointApp],
  ['shortcut', ShortcutApp],
  ['simpplr', App],
  ['slab', SlabApp],
  ['slack', SlackApp],
  ['smartsheet', App],
  ['stackoverflowteams', StackOverflowTeamsApp],
  ['system', App],
  ['trello', App],
  ['website', WebsiteApp],
  ['wrike', App],
  ['workday', WorkdayApp],
  ['zendesk', ZendeskApp],
  ['zoho_crm', App],
  ['zoho_projects', App],
]);

export const appsFactory = (flags: Partial<FeatureFlags>): App[] => {
  const definitions = appsDefinitionFactory(flags);
  const apps: App[] = [];

  for (const [shortName, ctor] of ctorMap) {
    const def = definitions[shortName];
    if (def?.controlEnabled) {
      // eslint-disable-next-line new-cap
      apps.push(new ctor(def));
    }
  }

  return apps.sort((a, b) => {
    // Sorts by name
    if (a.definition.displayName > b.definition.displayName) {
      return 1;
    }

    if (b.definition.displayName > a.definition.displayName) {
      return -1;
    }

    return 0;
  });
};
