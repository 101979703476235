import '../QAMessageListView';
import moment from 'moment';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  TOPIC_LINK_COPIED_FAILURE_MESSAGE,
  TOPIC_LINK_COPIED_SUCCESS_MESSAGE,
} from '../../../constants';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import {
  QAMessage,
  QATopicAccess,
  TopicVisibility,
} from '../../../models/QAmodels';
import { useQAController } from '../../../scripts/QAController';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useFlag, useToaster } from '../../../scripts/hooks';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { useNotifications } from '../../notifications/NotificationsContext';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../shadcn/lib/components/dropdownMenu';
import { StopSharingPopover } from './StopSharingPopover';
import { TopicSharePopover } from './TopicSharePopover';

interface QATopicDividerProps {
  conversationId?: string;
  isEditable?: boolean;
  isNewTopic: boolean;
  topicTitle?: string;
  topicTimestamp?: number;
  isDeletable?: boolean;
  topicAccess: QATopicAccess;
  messages: QAMessage[];
}

// eslint-disable-next-line max-lines-per-function
export const QATopicDivider: FC<QATopicDividerProps> = ({
  conversationId,
  isEditable,
  isNewTopic,
  topicTitle,
  topicTimestamp,
  isDeletable,
  topicAccess,
  messages,
}: QATopicDividerProps) => {
  const [newTopicTitle, setNewTopicTitle] = useState(topicTitle);
  const [connectionPopupOpen, setConnectionPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const toaster = useToaster();
  const history = useHistory();
  const qaController = useQAController();
  const { deleteNotificationsByTopicId } = useNotifications();

  const multiplayerEnabled = useFlag('multiplayerEnabled');

  const isTopicPage = useMemo(() => {
    return window.location.pathname.startsWith(`/topic/${conversationId!}`);
  }, [conversationId]);

  const showDropdownMenu = useMemo(
    () => !isTopicPage || isDeletable,
    [isDeletable, isTopicPage]
  );

  const handleUpdateTopicTitle = useCallback(async () => {
    setIsEditing(false);

    if (!conversationId || topicTitle === newTopicTitle) return;

    if (newTopicTitle === '' && divRef.current) {
      setNewTopicTitle(topicTitle);
      divRef.current.textContent = topicTitle ?? 'Example Topic Title';
      return;
    }

    try {
      await invokeFastApi({
        path: `/topics/topic/${conversationId}`,
        method: 'PATCH',
        body: { topic_title: newTopicTitle },
      });

      qaController.updateConversationDetails(conversationId, {
        topic_title: newTopicTitle,
      });
    } catch {
      toaster.failure('Failed to update topic title');
    }
  }, [conversationId, newTopicTitle, toaster, topicTitle, qaController]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (divRef.current) {
          divRef.current.blur();
        }
      }
    },
    []
  );

  const handleCopyLink = useCallback(
    async (onlyCopy = false) => {
      if (!conversationId) {
        toaster.failure(TOPIC_LINK_COPIED_FAILURE_MESSAGE);
        return;
      }

      const sharableLink = `${window.location.origin}/topic/${conversationId}`;
      navigator.clipboard.writeText(sharableLink);

      if (!onlyCopy && !topicAccess.isOrgWide) {
        try {
          await qaController.updateConversationVisibility(
            conversationId,
            TopicVisibility.ORG,
            topicAccess
          );
        } catch {
          toaster.failure('Failed to share topic');
          return;
        }
      }

      toaster.success(TOPIC_LINK_COPIED_SUCCESS_MESSAGE);
      trackEvent(AnalyticsEvent.SharableLinkCreated);
    },
    [conversationId, toaster, qaController, topicAccess]
  );

  const handleContinueTopic = useCallback(() => {
    history.push(`/topic/${conversationId!}`);
    trackEvent(AnalyticsEvent.ContinueTopic);
  }, [conversationId, history]);

  const handleArchiveTopic = useCallback(async () => {
    if (!conversationId) return;

    try {
      await invokeFastApi({
        path: `/topics/topic/${conversationId}`,
        method: 'DELETE',
      });

      qaController.removeTopic(conversationId);
      toaster.success('Topic deleted');
      trackEvent(AnalyticsEvent.DeleteTopic);

      deleteNotificationsByTopicId(conversationId);

      if (isTopicPage) {
        history.push(SidebarRoutes.JitQA);
      }
    } catch {
      toaster.failure('Failed to delete topic');
    }
  }, [
    conversationId,
    history,
    isTopicPage,
    qaController,
    toaster,
    deleteNotificationsByTopicId,
  ]);

  useEffect(() => {
    if (!topicTitle && divRef.current) {
      divRef.current.textContent = 'New Topic';
    }

    setNewTopicTitle(topicTitle);
  }, [topicTitle]);

  useEffect(() => {
    if (isEditing && divRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(divRef.current);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
      divRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div className="relative bg-white pt-[26px] pb-6">
      <div className="border-[0.5px] border-solid border-cloud-15" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-1 px-4 bg-white whitespace-nowrap">
        {isNewTopic ? (
          <div className="text-amethys-10">New Topic</div>
        ) : (
          <>
            {isEditable ? (
              <div
                className={`outline-none text-amethys-10 hidden sm:block  ${
                  isEditing ? 'cursor-text' : 'cursor-pointer max-w-[300px]'
                }`}
                contentEditable={isEditing}
                onBlur={handleUpdateTopicTitle}
                onClick={() => {
                  setIsEditing(true);
                }}
                onInput={(e) => {
                  setNewTopicTitle(e.currentTarget.textContent ?? topicTitle);
                }}
                onKeyDown={handleKeyDown}
                ref={divRef}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                suppressContentEditableWarning
              >
                {topicTitle}
              </div>
            ) : (
              <div>{topicTitle}</div>
            )}
            <div className="text-cloud-20 hidden sm:block">|</div>
            <div className="font-light">
              {moment(topicTimestamp).format('MMM D, h:mm a')}
            </div>
            <div className={isEditable ? '' : 'hidden'}>
              <div className="cursor-pointer ml-2 border border-cloud-15 border-solid shadow-flat flex gap-1 items-center bg-white rounded p-0.5 ">
                {!multiplayerEnabled && (
                  <>
                    <div
                      className="flex p-0.5 rounded hover:bg-cloud-15"
                      onClick={async () => handleCopyLink(false)}
                    >
                      <UIIcon
                        name="link"
                        tooltip="Share a link to this topic"
                      />
                    </div>
                    <div className="text-cloud-20 text-sm hidden sm:block">
                      |
                    </div>
                    {topicAccess.isOrgWide && (
                      <StopSharingPopover
                        conversationId={conversationId}
                        setIsOrgVisibility={() => {
                          qaController.updateConversationDetails(
                            conversationId!,
                            {
                              topicAccess: { ...topicAccess, isOrgWide: false },
                            }
                          );
                        }}
                      />
                    )}
                  </>
                )}

                {multiplayerEnabled &&
                  (topicAccess.isMultiplayer || topicAccess.isOrgWide) && (
                    <>
                      <div
                        className="flex p-0.5 rounded hover:bg-cloud-15"
                        onClick={async () => handleCopyLink(true)}
                      >
                        <UIIcon
                          name="link"
                          tooltip="Copy a link to this topic"
                        />
                      </div>
                      <div className="text-cloud-20 text-sm hidden sm:block">
                        |
                      </div>
                    </>
                  )}

                {multiplayerEnabled && (
                  <TopicSharePopover
                    conversationId={conversationId}
                    messages={messages}
                    showDivider={showDropdownMenu}
                    topicAccess={topicAccess}
                  />
                )}

                {showDropdownMenu && (
                  <DropdownMenu
                    modal={false}
                    onOpenChange={setConnectionPopupOpen}
                    open={connectionPopupOpen}
                  >
                    <DropdownMenuTrigger asChild>
                      <div className="flex p-0.5 rounded hover:bg-cloud-15 rotate-90">
                        <UIIcon name="more" />
                      </div>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent className="font-medium">
                      {!isTopicPage && (
                        <DropdownMenuItem>
                          <div
                            className="flex gap-2 items-center"
                            onClick={handleContinueTopic}
                          >
                            <UIIcon name="add-comment" />
                            <span>Continue Topic</span>
                          </div>
                        </DropdownMenuItem>
                      )}
                      {isEditable && (
                        <DropdownMenuItem>
                          <div
                            className="flex gap-2 items-center"
                            onClick={() => {
                              setIsEditing(true);
                            }}
                          >
                            <UIIcon name="pencil" />
                            <span>Rename Topic</span>
                          </div>
                        </DropdownMenuItem>
                      )}
                      {isDeletable && (
                        <DropdownMenuItem>
                          <div
                            className="flex gap-2 items-center"
                            onClick={handleArchiveTopic}
                          >
                            <UIIcon name="trash-can" />
                            <span>Delete Topic</span>
                          </div>
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
