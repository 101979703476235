import { gql } from 'graphql-tag';
import { conversationFields, userFields } from './fragments';

export const subscribeUserUpdate = gql`
  subscription ($userid: String!) {
    result: user(userId: $userid) {
      ...UserFields
    }
  }
  ${userFields}
`;

export const sharedConversationsSubscription = gql`
  subscription ($userid: String!) {
    result: user(userId: $userid) {
      conversationMemberships(
        condition: { role: EDITOR }
        first: 5
        orderBy: CONVERSATION_BY_CONVERSATION_ID__UPDATED_AT_DESC
      ) {
        nodes {
          role
          conversation {
            ...ConversationFields
          }
        }
      }
    }
  }
  ${conversationFields}
`;

export const managedBotsConversationsSubscription = gql`
  subscription ($userid: String!) {
    result: user(userId: $userid) {
      botsAdminsByAdminId {
        nodes {
          botId
          adminId
          bot {
            id
            botName
            isManaged
            botsConversations(
              orderBy: CONVERSATION_BY_CONVERSATION_ID__UPDATED_AT_DESC
              first: 5
            ) {
              nodes {
                conversationId
                createdAt
                conversation {
                  ...ConversationFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${conversationFields}
`;
