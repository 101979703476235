import classNames from 'classnames';
import './SourcesFilterSwitch.scss';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import { UploadedFile } from '../../models/File';
import { UserApp } from '../../models/User';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { ConnectedApps } from '../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../scripts/models/answers';
import {
  checkAiOnlyModeFromSources,
  checkWebModeFromSources,
} from '../../scripts/sources/common';
import { isSquareImg } from '../../scripts/utils';
import { Modal } from '../controls/ui/Modal/Modal';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../controls/ui/UITextbox/UITextbox';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../shadcn/lib/components/popover';
import { Tabs, TabsList, TabsTrigger } from '../shadcn/lib/components/tabs';
import { BotKnowledgeDisplay } from './BotKnowledgeDisplay/BotKnowledgeDisplay';
import { KnowledgeSelect } from './KnowledgeSelect/KnowledgeSelect';

type TSourcesFilterTabNames = 'aiOnly' | 'knowledge' | 'web';

interface ISourcesFilterSwitchProps {
  isBaseLLM: boolean;
  skillFilters: ConnectedApps[];
  fileFilters: UploadedFile[];
  answerFilters: Answer[];
  setIsBaseLLM: React.Dispatch<React.SetStateAction<boolean>>;
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
  setFileFilters: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  setAnswerFilters: React.Dispatch<React.SetStateAction<Answer[]>>;
  allSkills: ConnectedApps[];
  allSkillsUserApps: UserApp[];
  allFiles: UploadedFile[];
  allAnswers: Answer[];
  bot?: Bot;
  botsEnabledApps?: ConnectedApps[];
  botsEnabledUserApps?: UserApp[];
  error?: ReactNode;
  showConfigureAppSettings?: boolean;
  isDisabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const SourcesFilterSwitch: FC<ISourcesFilterSwitchProps> = ({
  isBaseLLM,
  skillFilters,
  fileFilters,
  answerFilters,
  setIsBaseLLM,
  setSkillFilters,
  setFileFilters,
  setAnswerFilters,
  allSkills,
  allSkillsUserApps,
  allFiles,
  allAnswers,
  bot,
  botsEnabledApps,
  botsEnabledUserApps,
  error,
  showConfigureAppSettings = false,
  isDisabled = false,
}) => {
  const {
    orgByOrgId: {
      preferences: { logo },
    },
  } = useUserSafe();

  const [tab, setTab] = useState<TSourcesFilterTabNames>(
    getTabValue(skillFilters, isBaseLLM, bot)
  );

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [isSquareLogo, setIsSquareLogo] = useState<boolean>(false);

  const [showConfigureAppSettingsModal, setShowConfigureAppSettingsModal] =
    useState<ConnectedApps | null>(null);

  const handleTabChange = useCallback((value: string) => {
    setTab(value as TSourcesFilterTabNames);
  }, []);

  const setSourceFiltersToKnowledge = useCallback(() => {
    if (skillFilters.some((app) => app.appName === 'web') || isBaseLLM) {
      setIsBaseLLM(false);
      setSkillFilters(allSkills);
      setFileFilters(allFiles);
      setAnswerFilters(allAnswers);
    }

    trackEvent(AnalyticsEvent.QAAppsFilterButtonClicked, {
      isBaseLLM,
      searchFilters: [...skillFilters, ...fileFilters, ...answerFilters],
    });
  }, [
    skillFilters,
    isBaseLLM,
    fileFilters,
    answerFilters,
    setIsBaseLLM,
    setSkillFilters,
    allSkills,
    setFileFilters,
    allFiles,
    setAnswerFilters,
    allAnswers,
  ]);

  const setSourceFiltersToWeb = useCallback(() => {
    setIsBaseLLM(false);
    setSkillFilters([{ appName: 'web', displayName: 'Web' }]);
    setFileFilters([]);
    setAnswerFilters([]);

    trackEvent(AnalyticsEvent.QAWebFilterButtonClicked);
  }, [setAnswerFilters, setFileFilters, setIsBaseLLM, setSkillFilters]);

  const setSourceFiltersToAiOnly = useCallback(() => {
    setIsBaseLLM(true);
    setSkillFilters([]);
    setFileFilters([]);
    setAnswerFilters([]);

    trackEvent(AnalyticsEvent.QAAiOnlyFilterButtonClicked);
  }, [setAnswerFilters, setFileFilters, setIsBaseLLM, setSkillFilters]);

  useEffect(() => {
    let isMounted = true;

    const checkIfSquareLogo = async () => {
      if (isMounted && logo) {
        const res = await isSquareImg(logo);
        setIsSquareLogo(res);
      } else {
        setIsSquareLogo(false);
      }
    };

    checkIfSquareLogo();

    return () => {
      isMounted = false;
    };
  }, [logo]);

  useEffect(() => {
    setTab(getTabValue(skillFilters, isBaseLLM, bot));
  }, [skillFilters, isBaseLLM, bot]);

  const isTabDisabled = (tabToCheck: TSourcesFilterTabNames) => {
    if (isDisabled) return true;

    return !!bot && tab !== tabToCheck;
  };

  return (
    <>
      <Tabs onValueChange={handleTabChange} value={tab}>
        <TabsList
          className={classNames('grid w-fit grid-cols-3', {
            'border-mahogany-10': !!error,
          })}
        >
          <TabsTrigger
            className="relative"
            disabled={isTabDisabled('knowledge')}
            onMouseDown={setSourceFiltersToKnowledge}
            value="knowledge"
          >
            {isSquareLogo ? (
              <UITooltip title="Search Apps, Files, and Answers">
                <div className="rounded flex">
                  <img alt="favicon" height={16} src={logo} width={16} />
                </div>
              </UITooltip>
            ) : (
              <UIIcon
                name="app"
                stroke
                tooltip="Search Apps, Files, and Answers"
              />
            )}
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'knowledge' ? '!text-black' : ''
              }`}
            >
              Apps
            </div>
            <Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
              <PopoverTrigger asChild>
                <div className="leading-[0]">
                  {(allSkills.length > 0 ||
                    allFiles.length > 0 ||
                    allAnswers.length > 0 ||
                    bot) && (
                    <UITooltip title="Select Apps, Files or Answers to search">
                      <UIIcon
                        className="selectChevron hover:pt-1"
                        name="arrow-down"
                      />
                    </UITooltip>
                  )}
                </div>
              </PopoverTrigger>
              <PopoverContent className="p-0 m-4 text-sm max-w-[270px] cursor-pointer z-[250]">
                <div>
                  {bot && botsEnabledApps && botsEnabledUserApps ? (
                    <BotKnowledgeDisplay
                      allAnswers={allAnswers}
                      allFiles={allFiles}
                      bot={bot}
                      botsEnabledApps={botsEnabledApps}
                      botsEnabledUserApps={botsEnabledUserApps}
                    />
                  ) : (
                    <KnowledgeSelect
                      allAnswers={allAnswers}
                      allFiles={allFiles}
                      allSkills={allSkills}
                      allSkillsUserApps={allSkillsUserApps}
                      answerFilters={answerFilters}
                      configureAppSettingsCallback={(app) => {
                        setPopoverOpen(false);
                        setShowConfigureAppSettingsModal(app);
                      }}
                      fileFilters={fileFilters}
                      setAnswerFilters={setAnswerFilters}
                      setFileFilters={setFileFilters}
                      setSkillFilters={setSkillFilters}
                      showConfigureAppSettings={showConfigureAppSettings}
                      skillFilters={skillFilters}
                    />
                  )}
                </div>
              </PopoverContent>
            </Popover>
          </TabsTrigger>

          <TabsTrigger
            disabled={isTabDisabled('web')}
            onMouseDown={setSourceFiltersToWeb}
            value="web"
          >
            <UIIcon name="web" size={16} tooltip="Search Web" type="apps" />
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'web' ? '!text-black' : ''
              }`}
            >
              Web
            </div>
          </TabsTrigger>

          <TabsTrigger
            disabled={isTabDisabled('aiOnly')}
            onMouseDown={setSourceFiltersToAiOnly}
            value="aiOnly"
          >
            <UIIcon name="ai-sparkle" tooltip="Ask AI without search" />
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'aiOnly' ? '!text-black' : ''
              }`}
            >
              AI Only
            </div>
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {/* Only show this if the error is an actual node */}
      {error && error !== true && (
        <div className="text-sm mt-[5px] text-mahogany-20">{error}</div>
      )}

      {showConfigureAppSettingsModal && (
        <ConfigureAppSettingsModal
          app={showConfigureAppSettingsModal}
          closeModal={() => {
            setShowConfigureAppSettingsModal(null);
            setPopoverOpen(true);
          }}
          setSkillFilters={setSkillFilters}
          skillFilters={skillFilters}
        />
      )}
    </>
  );
};

const getTabValue = (
  skillFilters: ConnectedApps[],
  isBaseLLM: boolean,
  bot?: Bot
) => {
  if (bot) {
    if (checkAiOnlyModeFromSources(bot.sources)) return 'aiOnly';
    if (checkWebModeFromSources(bot.sources)) return 'web';
    return 'knowledge';
  }

  if (isBaseLLM) return 'aiOnly';
  if (skillFilters.some((app) => app.appName === 'web')) return 'web';
  return 'knowledge';
};

const ConfigureAppSettingsModal: FC<{
  app: ConnectedApps;
  skillFilters: ConnectedApps[];
  closeModal: () => void;
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
}> = ({ app, skillFilters, closeModal, setSkillFilters }) => {
  const [files, setFiles] = useState('');
  const [folders, setFolders] = useState('');
  const [drives, setDrives] = useState('');
  const [channels, setChannels] = useState('');
  const [spaces, setSpaces] = useState('');

  const handleSave = useCallback(() => {
    setSkillFilters((prev) =>
      prev.map((item) =>
        item.id === app.id
          ? {
              ...item,
              appSpecificFilters: {
                ...(files && {
                  files: files.split(',').map((file) => file.trim()),
                }),
                ...(folders && {
                  folders: folders.split(',').map((folder) => folder.trim()),
                }),
                ...(drives && {
                  drives: drives.split(',').map((drive) => drive.trim()),
                }),
                ...(channels && {
                  channels: channels
                    .split(',')
                    .map((channel) => channel.trim()),
                }),
                ...(spaces && {
                  spaces: spaces.split(',').map((space) => space.trim()),
                }),
              },
            }
          : item
      )
    );

    closeModal();
  }, [
    closeModal,
    app.id,
    setSkillFilters,
    files,
    folders,
    drives,
    channels,
    spaces,
  ]);

  useEffect(() => {
    const matchedApp = skillFilters.find((item) => item.id === app.id);
    if (matchedApp) {
      setFiles(matchedApp.appSpecificFilters?.files?.join(',') ?? '');
      setFolders(matchedApp.appSpecificFilters?.folders?.join(',') ?? '');
      setDrives(matchedApp.appSpecificFilters?.drives?.join(',') ?? '');
      setChannels(matchedApp.appSpecificFilters?.channels?.join(',') ?? '');
      setSpaces(matchedApp.appSpecificFilters?.spaces?.join(',') ?? '');
    }
  }, [app.id, skillFilters]);

  return (
    <Modal
      disableCloseOnBackdropClick
      hasMaxZIndex
      modalClasses="max-w-xl"
      onClose={closeModal}
    >
      {(() => {
        if (app.appName === 'confluence') {
          return (
            <>
              <div className="flex gap-2 items-center">
                <UIIcon name={app.appName} type="apps" />
                <div className="text-lg font-semibold">
                  Specify sources in {app.displayName}
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2">
                <div className="font-bold">Space URLs</div>
                <UITextbox
                  onChange={setSpaces}
                  placeholder="Enter comma separated space URLs"
                  value={spaces}
                />
              </div>
            </>
          );
        }

        if (app.appName === 'gdrive') {
          return (
            <>
              <div className="flex gap-2 items-center">
                <UIIcon name={app.appName} type="apps" />
                <div className="text-lg font-semibold">
                  Specify sources in {app.displayName}
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2">
                <div className="font-bold">Document URLs</div>
                <UITextbox
                  onChange={setFiles}
                  placeholder="Enter comma separated Document URLs"
                  value={files}
                />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                <div className="font-bold">Folder URLs</div>
                <UITextbox
                  onChange={setFolders}
                  placeholder="Enter comma separated Folder URLs"
                  value={folders}
                />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                <div className="font-bold">Drive URLs</div>
                <UITextbox
                  onChange={setDrives}
                  placeholder="Enter comma separated Drive URLs"
                  value={drives}
                />
              </div>
            </>
          );
        }

        if (app.appName === 'notion') {
          return (
            <>
              <div className="flex gap-2 items-center">
                <UIIcon name={app.appName} type="apps" />
                <div className="text-lg font-semibold">
                  Specify sources in {app.displayName}
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2">
                <div className="font-bold">Teamspace URLs</div>
                <UITextbox
                  onChange={setSpaces}
                  placeholder="Enter comma separated invite links for teamspaces"
                  value={spaces}
                />
                <div className="text-gray-50 text-sm">
                  Follow{' '}
                  <a
                    href="https://www.notion.com/help/intro-to-teamspaces#add-members-to-a-teamspace"
                    rel="noreferrer"
                    target="_blank"
                  >
                    these
                  </a>{' '}
                  instructions to get invite links for your desired Notion
                  teamspaces.
                </div>
              </div>
            </>
          );
        }

        if (app.appName === 'slack') {
          return (
            <>
              <div className="flex gap-2 items-center">
                <UIIcon name={app.appName} type="apps" />
                <div className="text-lg font-semibold">
                  Specify sources in {app.displayName}
                </div>
              </div>
              <div className="mt-6 flex flex-col gap-2">
                <div className="font-bold">Channel Names</div>
                <UITextbox
                  onChange={setChannels}
                  placeholder="general, announcements, eng-team, ..."
                  value={channels}
                />
              </div>
            </>
          );
        }

        return null;
      })()}
      <div className="flex mt-4 gap-4 justify-between">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>
        <UIButton
          disabled={
            files === '' &&
            folders === '' &&
            drives === '' &&
            channels === '' &&
            spaces === ''
          }
          onClick={handleSave}
        >
          Save
        </UIButton>
      </div>
    </Modal>
  );
};
