import React, { ReactNode, useEffect } from 'react';
import { SuggestionDataItem } from 'react-mentions';
import { BotMinimal } from '../../models/Bots';
import { OrgMember } from '../../scripts/models/org-member';
import { CompanyLogo } from '../controls/CompanyLogo/CompanyLogo';
import { UserImage } from '../controls/UserImage/UserImage';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';

interface RenderSuggestionProps {
  entry: SuggestionDataItem;
  highlightedDisplay: ReactNode;
  focused: boolean;
  index: number;
  allBots: BotMinimal[];
  allMembers: OrgMember[];
  firstBotId?: string;
}

export const RenderSuggestion: React.FC<RenderSuggestionProps> = ({
  entry,
  highlightedDisplay,
  focused,
  allBots,
  index,
  allMembers,
  firstBotId,
}) => {
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (focused && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [focused, ref]);

  const bot = allBots.find((d) => d.id === entry.id);
  const member = allMembers.find((d) => d.user_id === entry.id);

  let mentionType: 'bot' | 'internal-dashworks-bot' | 'member';
  if (entry.id === 'internal-dashworks-bot') {
    mentionType = entry.id;
  } else if (member) {
    mentionType = 'member';
  } else {
    mentionType = 'bot';
  }

  return (
    <div key={entry.id} ref={ref}>
      <div>
        {entry.id === firstBotId && (
          <div className="flex justify-between p-2 pb-1">
            <div className="text-sm text-gray-50 font-medium">Bots</div>
          </div>
        )}
        {mentionType === 'bot' && (
          <BotSuggestion
            bot={bot}
            focused={focused}
            highlightedDisplay={highlightedDisplay}
          />
        )}
        {mentionType === 'internal-dashworks-bot' && (
          <DashworksBotSuggestion
            focused={focused}
            highlightedDisplay={highlightedDisplay}
          />
        )}
      </div>
      {mentionType === 'member' && member && (
        <MemberSuggestion
          focused={focused}
          highlightedDisplay={highlightedDisplay}
          index={index}
          member={member}
        />
      )}
    </div>
  );
};

const SuggestionWrapper: React.FC<{
  children: ReactNode;
  focused: boolean;
}> = ({ children, focused }) => {
  return (
    <div
      className={`flex items-center gap-2 p-2 cursor-pointer ${
        focused ? 'bg-cloud-10' : 'bg-white'
      }`}
    >
      {children}
    </div>
  );
};

const MemberSuggestion: React.FC<{
  index: number;
  member: OrgMember;
  focused: boolean;
  highlightedDisplay: ReactNode;
}> = ({ index, focused, member, highlightedDisplay }) => {
  const showPeopleHeading = index === 0;

  return (
    <div>
      {showPeopleHeading && (
        <div className="flex justify-between p-2 pb-1">
          <div className="text-sm text-gray-50 font-medium">People</div>
        </div>
      )}

      <SuggestionWrapper focused={focused}>
        <div className="max-h-5">
          <UserImage
            displayName={member.name}
            imageUrl={member.icon}
            size={20}
          />
        </div>
        {highlightedDisplay}
      </SuggestionWrapper>
    </div>
  );
};

const BotSuggestion: React.FC<{
  bot?: BotMinimal;
  focused: boolean;
  highlightedDisplay: ReactNode;
}> = ({ bot, focused, highlightedDisplay }) => {
  const icon = bot?.icon ?? '🤖';

  return (
    <SuggestionWrapper focused={focused}>
      <span className="text-xl/5">{icon}</span> {highlightedDisplay}
    </SuggestionWrapper>
  );
};

const DashworksBotSuggestion: React.FC<{
  focused: boolean;
  highlightedDisplay: ReactNode;
}> = ({ focused, highlightedDisplay }) => {
  return (
    <SuggestionWrapper focused={focused}>
      <CompanyLogo size={20} /> {highlightedDisplay}
    </SuggestionWrapper>
  );
};

export const EmailSuggestion: React.FC<{
  focused: boolean;
  highlightedDisplay: ReactNode;
  entry: SuggestionDataItem;
}> = ({ focused, highlightedDisplay, entry }) => {
  return (
    <div>
      <SuggestionWrapper focused={focused} key={entry.id}>
        <UIIcon name="mail" size={20} /> {highlightedDisplay}
      </SuggestionWrapper>
    </div>
  );
};
