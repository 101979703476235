import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { UploadStatus, useFiles } from '../../../scripts/hooks/files';
import { logError } from '../../../scripts/utils';

export interface UploadedFileResponse {
  id: string;
  name: string;
}

export interface QAFile {
  id: string;
  title: string;
}

interface QAFilesProps {
  uploadedFiles: QAFile[] | null;
  uploadFiles: (files: File[]) => void;
  deleteFile: (documentId: string) => Promise<void>;
  resetUploadedFiles: () => void;
  uploadStatus: UploadStatus;
}

const QAFilesContext = createContext<QAFilesProps | undefined>(undefined);

export const useQAFiles = (): QAFilesProps => {
  const ctx = useContext(QAFilesContext);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

interface QAFileProviderProps {
  children: ReactNode;
}

export const QAFilesProvider = ({
  children,
}: QAFileProviderProps): JSX.Element => {
  const [uploadedFiles, setUploadedFiles] = useState<
    | {
        id: string;
        title: string;
      }[]
    | null
  >(null);

  const { uploadQAFiles, uploadStatus } = useFiles();

  const uploadFiles = useCallback(async (files: File[]): Promise<void> => {
    const response = await uploadQAFiles(files);

    if (!response) return;

    setUploadedFiles((prev) => {
      const newFiles = response.map((item) => ({
        id: item.document.id,
        title: item.document.name,
      }));

      return prev ? [...prev, ...newFiles] : newFiles;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFileHandler = useCallback(async (documentId: string) => {
    setUploadedFiles((prev) =>
      prev ? prev.filter((file) => file.id !== documentId) : null
    );

    try {
      await invokeFastApi({
        path: `/document_uploads/${documentId}`,
        method: 'DELETE',
      });
    } catch (error) {
      logError(error);
    }
  }, []);

  const resetUploadedFiles = useCallback(() => {
    setUploadedFiles(null);
  }, []);

  const value = useMemo(
    () => ({
      uploadedFiles,
      uploadFiles,
      deleteFile: deleteFileHandler,
      resetUploadedFiles,
      uploadStatus,
    }),
    [
      uploadedFiles,
      uploadFiles,
      deleteFileHandler,
      resetUploadedFiles,
      uploadStatus,
    ]
  );

  return (
    <QAFilesContext.Provider value={value}>{children}</QAFilesContext.Provider>
  );
};
