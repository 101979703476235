import classNames from 'classnames';
import React from 'react';

interface IModalProps {
  modalClasses?: string;
  onClose: () => void;
  children: React.ReactNode;
  hasMaxZIndex?: boolean;
  disableCloseOnBackdropClick?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
  modalClasses,
  onClose,
  children,
  hasMaxZIndex = false,
  disableCloseOnBackdropClick = false,
}: IModalProps) => (
  <div
    aria-labelledby="modal-title"
    aria-modal="true"
    className="relative"
    role="dialog"
  >
    {/* Backdrop */}
    <div className="fixed inset-0 z-[150] w-full h-full bg-smoke-30 bg-opacity-40 overflow-y-auto animate-in fade-in-0" />

    {/* Modal Content */}
    <div
      className={classNames(
        'fixed inset-0 flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 z-[200] duration-200 animate-in fade-in-0 zoom-in-95',
        {
          'z-[99999]': hasMaxZIndex,
        }
      )}
      onClick={(e) => {
        if (!disableCloseOnBackdropClick && e.currentTarget === e.target) {
          onClose();
        }
      }}
    >
      <div
        className={`relative transform rounded-lg p-6 bg-white text-left shadow-xl transition-all w-full max-w-2xl ${
          modalClasses ?? ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  </div>
);
