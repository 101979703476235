import { ReactNode, useEffect, useState } from 'react';
import { RecommendedSlackbotChannel } from '../../models/Bots';
import { invokeFastApi } from '../apis/fastapi';
import { logError } from '../utils';
import { useToaster } from './toast';

export interface AutoJoinChannelBody {
  user_slack_app_id: number;
  org_id: string;
  team_id: string;
  channel_id: string;
}

interface RecommendedSlackbotChannelsResponse {
  recommendedSlackbotChannels: RecommendedSlackbotChannel[];
  loading: boolean;
  error: boolean;
  getRecommendedSlackbotChannels: () => Promise<void>;
  autoJoinRecommendedSlackbotChannel: (
    body: AutoJoinChannelBody,
    autoJoinFailureToastMessage: ReactNode
  ) => Promise<void>;
}

export const useRecommendedSlackbotChannels =
  (): RecommendedSlackbotChannelsResponse => {
    const [recommendedSlackbotChannels, setRecommendedSlackbotChannels] =
      useState<RecommendedSlackbotChannel[]>([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const toaster = useToaster();

    const getRecommendedSlackbotChannels = async () => {
      setRecommendedSlackbotChannels([]);
      setLoading(true);
      setError(false);

      try {
        const { recommended_channels } = await invokeFastApi<{
          recommended_channels: RecommendedSlackbotChannel[];
        }>({
          path: '/bots/channels/recommended',
          method: 'GET',
        });

        setRecommendedSlackbotChannels(recommended_channels);
      } catch (fetchError) {
        logError(fetchError);
        setError(true);
        toaster.failure('Failed to fetch recommended slackbot channels');
      }

      setLoading(false);
    };

    const autoJoinRecommendedSlackbotChannel = async (
      body: AutoJoinChannelBody,
      autoJoinFailureToastMessage: ReactNode
    ) => {
      try {
        await invokeFastApi({
          // eslint-disable-next-line @cspell/spellchecker
          path: '/bots/channels/recommended/autojoin',
          method: 'POST',
          body,
        });

        toaster.success('Slackbot added successfully. Opening channel...');
      } catch (fetchError) {
        logError(fetchError);
        toaster.failure(autoJoinFailureToastMessage);
      }
    };

    useEffect(() => {
      getRecommendedSlackbotChannels();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      recommendedSlackbotChannels,
      loading,
      error,
      getRecommendedSlackbotChannels,
      autoJoinRecommendedSlackbotChannel,
    };
  };
