import { Bot } from '../../models/Bots';
import { UserApp } from '../../models/User';
import { Action } from '../actions';

export enum BotsAction {
  POPULATE_BOTS = 'BOTS_POPULATE_BOTS',
  POPULATE_ENABLED_USER_APPS = 'BOTS_POPULATE_ENABLED_USER_APPS',
  POPULATE_HAS_RECOMMENDED_CHANNELS = 'BOTS_POPULATE_HAS_RECOMMENDED_CHANNELS',
}

export function populateBots(payload: Bot[]): Action {
  return {
    type: BotsAction.POPULATE_BOTS,
    payload,
  };
}

export function populateEnabledUserApps(payload: UserApp[]): Action {
  return {
    type: BotsAction.POPULATE_ENABLED_USER_APPS,
    payload,
  };
}

export function populateHasRecommendedChannels(payload: boolean): Action {
  return {
    type: BotsAction.POPULATE_HAS_RECOMMENDED_CHANNELS,
    payload,
  };
}
