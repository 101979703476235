import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UploadedFile } from '../../../models/File';
import { SupportedLlm, UserApp } from '../../../models/User';
import { usePreferredLlm, useUserSafe } from '../../../scripts/hooks';
import { useAnswers } from '../../../scripts/hooks/answers';
import { UploadStatus, useFiles } from '../../../scripts/hooks/files';
import {
  ConnectedApps,
  useSortedInstantApps,
} from '../../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../../scripts/models/answers';

interface QATextInputBoxStates {
  dynamicPlaceholder: string;
  searchQuery: string;
  mentions: string | undefined;
  userMentions: string[];
  userEmailMentions: string[];
  skillFilters: ConnectedApps[];
  fileFilters: UploadedFile[];
  answerFilters: Answer[];
  isBaseLLM: boolean;
  selectedLlm: SupportedLlm | null;
  setDynamicPlaceholder: React.Dispatch<React.SetStateAction<string>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setMentions: React.Dispatch<React.SetStateAction<string | undefined>>;
  setUserMentions: React.Dispatch<React.SetStateAction<string[]>>;
  setUserEmailMentions: React.Dispatch<React.SetStateAction<string[]>>;
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
  setFileFilters: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  setAnswerFilters: React.Dispatch<React.SetStateAction<Answer[]>>;
  setIsBaseLLM: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedLlm: React.Dispatch<React.SetStateAction<SupportedLlm | null>>;
  allSkills: ConnectedApps[];
  allSkillsUserApps: UserApp[];
  inputRef: React.RefObject<HTMLTextAreaElement>;
  // files
  allFiles: UploadedFile[];
  filesLoading: boolean;
  fileUploadStatus: UploadStatus;
  uploadFiles: (
    file: File[],
    isZipFile: boolean,
    siteId: string
  ) => Promise<void>;
  deleteFile: (id: string) => Promise<void>;
  // answers
  allAnswers: Answer[];
}

const context = createContext<QATextInputBoxStates | undefined>(undefined);

export const useQATextInputBoxStates = (): QATextInputBoxStates => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

export const QATextInputBoxStatesProvider: FC = ({ children }) => {
  const user = useUserSafe();

  const { sortedInstantConnectedApps: allSkills } = useSortedInstantApps();
  const {
    files: allFiles,
    loading: filesLoading,
    uploadStatus: fileUploadStatus,
    uploadFiles,
    deleteFile,
  } = useFiles();

  const { answers: allAnswers } = useAnswers();
  const preferredLlm = usePreferredLlm();

  const [dynamicPlaceholder, setDynamicPlaceholder] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [mentions, setMentions] = useState<string | undefined>();
  const [userMentions, setUserMentions] = useState<string[]>([]);
  const [userEmailMentions, setUserEmailMentions] = useState<string[]>([]);
  const [skillFilters, setSkillFilters] = useState<ConnectedApps[]>(allSkills);
  const [fileFilters, setFileFilters] = useState<UploadedFile[]>(allFiles);
  const [answerFilters, setAnswerFilters] = useState<Answer[]>(allAnswers);
  const [isBaseLLM, setIsBaseLLM] = useState(false);

  const [selectedLlm, setSelectedLlm] = useState<SupportedLlm | null>(
    preferredLlm
  );

  const previousAllSkillsRef = useRef(allSkills);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const allSkillsUserApps = useMemo(() => {
    const personalUserApps = user.usersApps.nodes.filter(
      (app) => app.org === ''
    );

    const orgUserApps = user.orgByOrgId.usersAppsByOrg.nodes;
    const allUserApps = [...personalUserApps, ...orgUserApps];

    return allUserApps.filter((userApp) =>
      allSkills.some((skill) => skill.id === userApp.id)
    );
  }, [allSkills, user.orgByOrgId.usersAppsByOrg.nodes, user.usersApps.nodes]);

  const states = useMemo(
    () => ({
      dynamicPlaceholder,
      searchQuery,
      mentions,
      skillFilters,
      fileFilters,
      answerFilters,
      isBaseLLM,
      selectedLlm,
      setDynamicPlaceholder,
      setSearchQuery,
      setMentions,
      setSkillFilters,
      setFileFilters,
      setAnswerFilters,
      setIsBaseLLM,
      setSelectedLlm,
      allSkills,
      allSkillsUserApps,
      inputRef,
      allFiles,
      filesLoading,
      fileUploadStatus,
      uploadFiles,
      deleteFile,
      allAnswers,
      userMentions,
      setUserMentions,
      userEmailMentions,
      setUserEmailMentions,
    }),
    [
      dynamicPlaceholder,
      searchQuery,
      mentions,
      skillFilters,
      fileFilters,
      answerFilters,
      isBaseLLM,
      selectedLlm,
      allSkills,
      allSkillsUserApps,
      allFiles,
      filesLoading,
      fileUploadStatus,
      uploadFiles,
      deleteFile,
      allAnswers,
      userMentions,
      userEmailMentions,
    ]
  );

  useEffect(() => {
    if (previousAllSkillsRef.current.length !== allSkills.length) {
      setSkillFilters(allSkills);
    }

    // Update the ref after making the comparison
    previousAllSkillsRef.current = allSkills;
  }, [allSkills]);

  useEffect(() => {
    setFileFilters(allFiles);
  }, [allFiles]);

  useEffect(() => {
    setAnswerFilters(allAnswers);
  }, [allAnswers]);

  return <context.Provider value={states}>{children}</context.Provider>;
};
