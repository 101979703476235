import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useUserSafe } from '../../scripts/hooks';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, app, setInProgress }) => {
    const clickConnect = useCallback(async () => {
      setInProgress(true);
      return handleOAuthInstall({});
    }, [handleOAuthInstall, setInProgress]);

    const user = useUserSafe();

    return (
      <>
        <p className="firstNotice">
          <strong>
            <p>If you are a {app.definition.displayName} admin:</p>
          </strong>
          <ul>
            <li>
              You must grant consent on behalf of the organization to allow
              other members in your workspace to connect the integration
            </li>
            <li>Make sure {user.email} is an Azure Global Administrator</li>
            <li>
              After clicking Connect, please sign in with {user.email} by
              entering your email and password instead of selecting from already
              signed in users
            </li>
          </ul>

          <strong>
            If you are not a {app.definition.displayName} admin, you can connect
            the integration once your {app.definition.displayName} admin has
            granted consent. Click the Connect button below to check if your
            admin has granted consent.
          </strong>
        </p>

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
