import { BotMinimal } from '../../models/Bots';

export const DEFAULT_BOTS_MINIMAL_STATE: BotsMinimalState = {
  bots: [],
  searchedQueries: [],
};

export interface BotsMinimalState {
  bots: BotMinimal[];
  searchedQueries: string[];
}
