import React, { FC, useCallback, useMemo, useState } from 'react';
import { Bot, SlackbotChannel } from '../../models/Bots';
import { SupportedLlm } from '../../models/User';
import { useFlag, useUserSafe } from '../../scripts/hooks';
import { UpdateSlackbotChannelParams } from '../../scripts/hooks/slackbotChannels';
import { llms } from '../LLMSelect/LLMSelectList';
import { Modal } from '../controls/ui/Modal/Modal';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import { BotsSelectButtonSettings } from '../general/botsSelect/BotsSelectSettings';

/* eslint-disable @cspell/spellchecker */
export const ChannelsModal: FC<{
  bots: Bot[];
  channel: SlackbotChannel;
  closeModal: () => void;
  updateSlackbotChannel: (params: UpdateSlackbotChannelParams) => Promise<void>;
  // eslint-disable-next-line max-lines-per-function
}> = ({ bots, channel, closeModal, updateSlackbotChannel }) => {
  const user = useUserSafe();

  const [botId, setBotId] = useState(channel.bot_id);
  const [triggerOnlyWhenTagged, setTriggerOnlyWhenTagged] = useState(
    channel.trigger_only_when_tagged
  );

  const [confidenceThreshold, setConfidenceThreshold] = useState(
    channel.auto_reply_threshold
  );

  const [acknowledgeQuestion, setAcknowledgeQuestion] = useState(
    channel.acknowledge_question
  );

  const [fallbackMessage, setFallbackMessage] = useState(
    channel.fallback_message
  );

  const [createDraftReply, setCreateDraftReply] = useState(
    channel.create_draft
  );

  const [loading, setLoading] = useState(false);

  const isTokenProbsEnabled = useFlag('tokenProbsConfidenceScore');

  const isConfidenceScoreEnabled = useMemo(() => {
    const botPreferredLlm = bots.find((bot) => bot.id === botId)?.preferred_llm;
    return botPreferredLlm === SupportedLlm.OPENAI_GPT4_OMNI;
  }, [botId, bots]);

  const MODEL_NAME_FOR_CONFIDENCE_SCORING = useMemo(
    () =>
      llms.find((llm) => llm.value === SupportedLlm.OPENAI_GPT4_OMNI)
        ?.displayName ?? '',
    []
  );

  const hasEditPermissions = useMemo(() => {
    return user.admin || channel.author.email === user.email;
  }, [channel.author.email, user.admin, user.email]);

  const currentBot = useMemo(
    () => bots.find((bot) => bot.id === botId),
    [bots, botId]
  );

  const handleUpdateSlackbotChannel = useCallback(async () => {
    setLoading(true);

    await updateSlackbotChannel({
      team_id: channel.team_id,
      channel_id: channel.channel_id,
      bot_id: botId,
      trigger_only_when_tagged: triggerOnlyWhenTagged,
      auto_reply_threshold: confidenceThreshold,
      acknowledge_question: acknowledgeQuestion,
      fallback_message: fallbackMessage,
      create_draft: createDraftReply,
    });

    setLoading(false);
    closeModal();
  }, [
    acknowledgeQuestion,
    botId,
    channel.channel_id,
    channel.team_id,
    closeModal,
    confidenceThreshold,
    createDraftReply,
    fallbackMessage,
    triggerOnlyWhenTagged,
    updateSlackbotChannel,
  ]);

  return (
    <Modal onClose={closeModal}>
      <div className="flex flex-col gap-6">
        <div className="flex gap-2 items-center">
          <UIIcon name="cog" size={32} />
          <h2 className="text-2xl m-0 font-bold">Edit Channel</h2>
        </div>

        <div>
          <div className="font-bold">Channel Name</div>
          {channel.channel_name}
        </div>

        {currentBot && (
          <div>
            <div className="font-bold">Bot</div>
            {hasEditPermissions ? (
              <BotsSelectButtonSettings
                bots={bots}
                currentBot={currentBot}
                setBotId={setBotId}
              />
            ) : (
              <div className="flex gap-2 items-center">
                <div className="text-xl/5">{currentBot.icon}</div>
                <div>{currentBot.bot_name}</div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-start">
            <input
              checked={triggerOnlyWhenTagged}
              className="mt-1.5"
              disabled={!hasEditPermissions}
              onChange={() => {
                setTriggerOnlyWhenTagged(!triggerOnlyWhenTagged);
              }}
              onClick={stopPropagation}
              type="checkbox"
            />
            <div>
              <div className="font-bold">Reply only when tagged</div>
              <div className="text-gray-50 text-sm">
                The bot will only respond when it is tagged in a question.
              </div>
            </div>
          </div>

          <div className="flex gap-2 items-start">
            <input
              checked={acknowledgeQuestion}
              className="mt-1.5"
              disabled={!hasEditPermissions}
              onChange={() => {
                setAcknowledgeQuestion(!acknowledgeQuestion);
              }}
              onClick={stopPropagation}
              type="checkbox"
            />
            <div>
              <div className="font-bold">Acknowledge question</div>
              <div className="text-gray-50 text-sm">
                The bot will acknowledge the question immediately with an emoji
                reaction.
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1">
              <div className="font-bold">
                Confidence threshold for Auto-reply
              </div>
              {isTokenProbsEnabled && (
                <UITooltip
                  title={`Confidence threshold is supported only for Bots with the following LLMs: ${MODEL_NAME_FOR_CONFIDENCE_SCORING}`}
                >
                  <UIIcon name="info" />
                </UITooltip>
              )}
            </div>
            <input
              className="w-full p-2 border rounded"
              disabled={
                !hasEditPermissions ||
                (isTokenProbsEnabled && !isConfidenceScoreEnabled)
              }
              max="100"
              min="0"
              onChange={(e) => {
                setConfidenceThreshold(Number.parseFloat(e.target.value) / 100);
              }}
              step="1"
              type="number"
              value={confidenceThreshold * 100}
            />
            <div className="text-gray-50 text-sm">
              Set the minimum confidence level (0-100) for the bot to
              automatically reply to a question. Set 0 to reply to all
              questions.
              {isTokenProbsEnabled && !isConfidenceScoreEnabled && (
                <div className="text-mahogany-20">
                  Confidence threshold is supported only for Bots with the
                  following LLMs: {MODEL_NAME_FOR_CONFIDENCE_SCORING}.
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-bold">Fallback message</div>
            <textarea
              className="w-full p-2 border rounded"
              disabled={
                !hasEditPermissions ||
                (isTokenProbsEnabled
                  ? !isConfidenceScoreEnabled || confidenceThreshold === 0
                  : confidenceThreshold === 0)
              }
              onChange={(e) => {
                setFallbackMessage(e.target.value);
              }}
              placeholder="Enter fallback message for low confidence responses"
              rows={3}
              value={fallbackMessage}
            />
            <div className="text-gray-50 text-sm">
              Message to send when the bot's confidence level is below the
              minimum threshold. Leave empty to disable fallback messages.
            </div>
          </div>

          <div className="flex gap-2 items-start">
            <input
              checked={createDraftReply}
              className="mt-1.5"
              disabled={
                !hasEditPermissions ||
                (isTokenProbsEnabled
                  ? !isConfidenceScoreEnabled ||
                    confidenceThreshold === 0 ||
                    fallbackMessage === ''
                  : confidenceThreshold === 0)
              }
              onChange={() => {
                setCreateDraftReply(!createDraftReply);
              }}
              onClick={stopPropagation}
              type="checkbox"
            />
            <div>
              <div className="font-bold">
                Create draft reply below confidence threshold
              </div>
              <div className="text-gray-50 text-sm">
                Create a draft reply only visible to the bot creator when the
                bot's confidence level is below the minimum threshold.
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="flex justify-between mt-6">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>

        <UIButton
          disabled={
            !hasEditPermissions ||
            (botId === channel.bot_id &&
              triggerOnlyWhenTagged === channel.trigger_only_when_tagged &&
              confidenceThreshold === channel.auto_reply_threshold &&
              acknowledgeQuestion === channel.acknowledge_question &&
              fallbackMessage === channel.fallback_message &&
              createDraftReply === channel.create_draft)
          }
          onClick={handleUpdateSlackbotChannel}
          processing={loading}
          type="primary"
        >
          Confirm
        </UIButton>
      </footer>
    </Modal>
  );
};

const stopPropagation = (event: React.SyntheticEvent) => {
  event.stopPropagation();
};
