import { Bot } from '../../models/Bots';
import { UserApp } from '../../models/User';
import { Action } from '../actions';
import { GlobalState } from '../state';
import { BotsAction } from './actions';

export const botsReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  const botsState = { ...state.bots };

  switch (action.type) {
    case BotsAction.POPULATE_BOTS: {
      const newBots = action.payload as Bot[];
      botsState.bots = newBots;

      break;
    }

    case BotsAction.POPULATE_ENABLED_USER_APPS: {
      const newEnabledUserApps = action.payload as UserApp[];
      botsState.enabledUserApps = newEnabledUserApps;

      break;
    }

    case BotsAction.POPULATE_HAS_RECOMMENDED_CHANNELS: {
      const newHasRecommendedChannels = action.payload as boolean;
      botsState.hasRecommendedChannels = newHasRecommendedChannels;

      break;
    }
  }

  return {
    ...state,
    bots: botsState,
  };
};
