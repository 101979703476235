/* eslint-disable max-lines */
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { v4 as uuidV4 } from 'uuid';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import { Bot, CreatorDetails } from '../../../models/Bots';
import {
  ConversationMemberRole,
  DEFAULT_TOPIC_ACCESS,
  QAMessage,
  QAStages,
  QATopicAccess,
} from '../../../models/QAmodels';
import { Sources, SupportedLlm } from '../../../models/User';
import { WorkflowModalTypes } from '../../../models/Workflows';
import {
  MAX_QUERY_LENGTH,
  QAControllerEventArgs,
  useQAController,
} from '../../../scripts/QAController';
import { sendMessage } from '../../../scripts/apis/message';
import {
  AnalyticsEvent,
  EventSubSurface,
  EventSurface,
} from '../../../scripts/constants/analytics-event';
import {
  QueryParams,
  useFlag,
  useGlobalState,
  useToaster,
  useUserSafe,
} from '../../../scripts/hooks';
import { useBots } from '../../../scripts/hooks/bots';
import { UploadStatus } from '../../../scripts/hooks/files';
import { useMembers } from '../../../scripts/hooks/members';
import { OrgMember } from '../../../scripts/models/org-member';
import { getSourcesFromFilters } from '../../../scripts/sources/common';
import { logError } from '../../../scripts/utils';
import { LLMSelectPopoverBtn } from '../../LLMSelect/LLMSelectPopoverBtn';
import { AttachFile } from '../../SourcesFilterSwitch/AttachFile/AttachFile';
import { SourcesFilterSwitch } from '../../SourcesFilterSwitch/SourcesFilterSwitch';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import { BotsSelectButtonDashAi } from '../../general/botsSelect/BotsSelectDashAi';
import { MentionsSelectButtonDashAi } from '../../general/mentionsSelect/MentionsSelectDashAi';
import {
  onMessageAddedFn,
  useRealtimeChat,
} from '../../realtimeChat/RealtimeChatContext';
import { QABotsMentionsInput } from '../QABotsMentionsInput';
import { DASHWORKS_BOT_ID, QAMentionsInput } from '../QAMentionsInput';
import { QAAttachedFiles } from '../files/QAAttachedFiles';
import { QAFile, useQAFiles } from '../files/QAFilesContext';
import { useQATextInputBoxStates } from './QATextInputBoxStates';

export interface SendQueryArgs {
  queryText: string;
  conversation_id: string;
  sources?: Sources;
  llm_preference: SupportedLlm | null;
  continueTopic?: boolean;
  bot_id?: string;
  uploaded_conversation_files?: QAFile[];
  author?: CreatorDetails;
  topicAccess: QATopicAccess;
  onMessageAdded: onMessageAddedFn;
}

export interface QATextInputBoxProps {
  className?: string;
  autofocus?: boolean;
  hideNewTopicButton?: boolean;
  hideStopAnswerGenerationButton?: boolean;
  showPopupFromBottom?: boolean;
  disableNewTopicButton?: boolean;
  disableNewTopicButtonReason?: string;
  continueConversationId?: string;
  setOpenPaywallModal(): void;
  sendQueryCallback?(): void;
  newTopicCallback?(): void;
}

// eslint-disable-next-line max-lines-per-function
export const QATextInputBox: FC<QATextInputBoxProps> = ({
  className = '',
  autofocus = true,
  hideNewTopicButton = false,
  hideStopAnswerGenerationButton = false,
  showPopupFromBottom = false,
  disableNewTopicButton = false,
  disableNewTopicButtonReason = 'Already on a new topic',
  continueConversationId,
  setOpenPaywallModal,
  sendQueryCallback,
  newTopicCallback,
}: QATextInputBoxProps) => {
  const history = useHistory();
  const multiplayerEnabled = useFlag('multiplayerEnabled');
  const { broadcastQAMessage } = useRealtimeChat();

  const toaster = useToaster();
  const userEmail = useUserSafe((u) => u.email);
  const { userDisplayName, userPhotoUrl } = useGlobalState(
    (state) => state.meta
  );

  const [queryParams] = useQueryParams({
    [QueryParams.Search]: StringParam,
    [QueryParams.Omnibox]: BooleanParam,
  });

  const {
    uploadFiles,
    uploadedFiles: uploadedQAFiles,
    resetUploadedFiles: resetUploadedQAFiles,
    uploadStatus: QAFileUploadStatus,
    deleteFile: deleteQAFile,
  } = useQAFiles();

  const isLoaded = useRef(false);

  const {
    dynamicPlaceholder,
    searchQuery,
    mentions,
    skillFilters,
    fileFilters,
    answerFilters,
    isBaseLLM,
    selectedLlm,
    setDynamicPlaceholder,
    setSearchQuery,
    setMentions,
    setSkillFilters,
    setFileFilters,
    setAnswerFilters,
    setIsBaseLLM,
    setSelectedLlm,
    allSkills,
    allSkillsUserApps,
    allFiles,
    allAnswers,
    inputRef,
    setUserMentions,
    userMentions,
    userEmailMentions,
    setUserEmailMentions,
  } = useQATextInputBoxStates();

  const {
    bots,
    botsEnabledApps,
    botsEnabledUserApps,
    loading: botsLoading,
  } = useBots();

  const {
    allMembers,
    loading: allMembersLoading,
    fetchMembersByQuery,
  } = useMembers();

  const qaController = useQAController();
  const { currentStage } = qaController.useProgressStage();
  const { isNewTopic } = qaController.getIsNewTopic();

  const messages = qaController.useMessages();
  const currentTopicMessages = qaController.useCurrentTopicMessages();
  const conversation_id = messages[messages.length - 1]?.conversation_id;

  const currentConversationId = useMemo(
    () => !isNewTopic && (continueConversationId ?? conversation_id),
    [continueConversationId, conversation_id, isNewTopic]
  );

  const latestQAMessage = currentConversationId
    ? qaController.getLatestQAMessage(
        messages,
        currentTopicMessages,
        currentConversationId
      )
    : null;

  const topicAccess: QATopicAccess =
    latestQAMessage?.topicAccess ?? DEFAULT_TOPIC_ACCESS;

  const { topicFilesMap } = qaController.getTopicFilesMap();

  const topicFiles = useMemo(
    () =>
      currentConversationId
        ? qaController.getTopicFiles(currentConversationId, topicFilesMap)
        : [],
    [currentConversationId, qaController, topicFilesMap]
  );

  const isBotMentioned = useMemo(() => {
    if (!mentions) return false;
    return Boolean(bots.some((b) => b.id === mentions));
  }, [mentions, bots]);

  const isSelectModesDisabled = useMemo(() => {
    if (userMentions.length > 0 && !userMentions.includes(DASHWORKS_BOT_ID))
      return true;
    if (!topicAccess.isMultiplayer) return false;
    if (mentions) return false;
    return true;
  }, [topicAccess, mentions, userMentions]);

  const hasConversationFiles = useMemo(() => {
    return Boolean(!!uploadedQAFiles?.length || topicFiles.length);
  }, [uploadedQAFiles, topicFiles]);

  const placeholderText = useMemo(() => {
    if (latestQAMessage?.topicAccess?.isMultiplayer) {
      return 'Send a message in this topic or tag @Dashworks to find answers from your Apps';
    }

    const isAIOnlyMode = Boolean(isBaseLLM);
    const isWebMode =
      skillFilters.length === 1 && skillFilters[0]?.appName === 'web';

    const hasContextualFiles = Boolean(
      topicFiles.length || uploadedQAFiles?.length
    );

    if (hasContextualFiles) {
      if (isAIOnlyMode) {
        return 'Ask to find answers from the Attached files';
      }

      if (isWebMode) {
        return 'Ask to find answers from the Web and the Attached files';
      }

      return 'Ask to find answers from your Apps and the Attached files';
    }

    if (isAIOnlyMode) {
      return 'Ask AI without searching your Apps or Web';
    }

    if (isWebMode) {
      return 'Ask to find answers from the Web';
    }

    return 'Ask to find answers from your Apps';
  }, [isBaseLLM, skillFilters, latestQAMessage, topicFiles, uploadedQAFiles]);

  useEffect(() => {
    if (currentConversationId) {
      qaController.setCurrentTopicId(currentConversationId);
    } else {
      qaController.setCurrentTopicId(null);
    }
  }, [currentConversationId, qaController]);

  const cleanupAfterSendingMessage = useCallback(() => {
    setMentions(undefined);
    qaController.setIsNewTopic(false);
    setSearchQuery('');
    resetUploadedQAFiles();
    setUserMentions([]);
    setUserEmailMentions([]);
  }, [
    resetUploadedQAFiles,
    setMentions,
    setSearchQuery,
    qaController,
    setUserMentions,
    setUserEmailMentions,
  ]);

  const isDashAIQuery = useCallback(() => {
    if (!multiplayerEnabled) return true;

    const taggedUsers: OrgMember[] = userMentions
      .map((mention) => allMembers.find((m) => m.user_id === mention))
      .filter((m) => !!m) as OrgMember[];

    if (taggedUsers.length > 0) return false;

    if (userEmailMentions.length > 0) return false;

    if (mentions) {
      const bot = bots.find((b) => b.id === mentions);
      if (bot) return true;
    }

    if (!topicAccess.isMultiplayer || isNewTopic) {
      return true;
    }

    if (mentions === DASHWORKS_BOT_ID) return true;

    return false;
  }, [
    mentions,
    bots,
    allMembers,
    multiplayerEnabled,
    isNewTopic,
    userMentions,
    userEmailMentions,
    topicAccess,
  ]);

  const sendQuery = useCallback(
    // eslint-disable-next-line complexity, max-lines-per-function
    async (query: string, fromOmnibox?: boolean) => {
      if (query.trim().length === 0) {
        return;
      }

      if (currentStage !== QAStages.WAIT_USER_INPUT) {
        trackEvent(AnalyticsEvent.QAUserInputWhilePreviousResponseLoading);
        toaster.failure(
          'Please hold on while we generate an answer for your previous query.'
        );

        return;
      }

      let conversationId: string | undefined;

      if (!isNewTopic) {
        conversationId = conversation_id;
      }

      if (continueConversationId) {
        conversationId = continueConversationId;
      }

      if (!conversationId) {
        conversationId = uuidV4().replace(/-/g, '');
      }

      const author = {
        email: userEmail,
        display_name: userDisplayName,
        icon: userPhotoUrl,
      };

      if (!isDashAIQuery()) {
        const taggedUsers: OrgMember[] = userMentions
          .map((mention) => allMembers.find((m) => m.user_id === mention))
          .filter((m) => !!m) as OrgMember[];

        if (sendQueryCallback) {
          sendQueryCallback();
        }

        const userAddedQAMessage = qaController.addMessageToTopic({
          queryText: query,
          continueTopic: !!continueConversationId,
          conversation_id: conversationId,
          author,
          sender: 'USER',
          topicAccess,
        });

        qaController.setNewlyAddedConversationMembers(
          taggedUsers.map((user) => {
            return {
              display_name: user.name ?? user.email,
              email: user.email,
              conversation_id: conversationId ?? '',
              icon: user.icon,
              role: ConversationMemberRole.EDITOR,
            };
          })
        );

        qaController.setNewlyAddedConversationMembers(
          userEmailMentions.map((email) => {
            return {
              display_name: email,
              email,
              conversation_id: conversationId ?? '',
              icon: '',
              role: ConversationMemberRole.EDITOR,
            };
          })
        );

        const isNotAMultiplayerTopic = !topicAccess.isMultiplayer;

        if (isNewTopic || isNotAMultiplayerTopic) {
          toaster.success('This topic is now shared with the tagged users.');
        }

        qaController.updateConversationDetails(conversationId, {
          topicAccess: {
            ...topicAccess,
            isMultiplayer: true,
          },
        });

        try {
          const message = query;

          cleanupAfterSendingMessage();

          const topic_title = await sendMessage(
            message,
            {
              id: userEmail,
              email: userEmail,
              name: userDisplayName,
              avatar: userPhotoUrl,
            },
            conversationId,
            taggedUsers,
            userEmailMentions
          );

          if (topic_title) {
            qaController.updateMessageTopicTitle(conversationId, topic_title);
          }

          broadcastQAMessage(userAddedQAMessage);

          if (taggedUsers.length > 0) {
            trackEvent(
              AnalyticsEvent.QATaggedUsersInMessage,
              {
                user: userEmail,
              },
              {
                question: query,
                taggedUsers,
                conversationId,
                message_row_id: userAddedQAMessage.row_id,
                message_id: userAddedQAMessage.message_id,
                message_text: userAddedQAMessage.messageText,
              }
            );
          }
        } catch {
          toaster.failure('Error sending message. Please try again.');
        }

        return;
      }

      if (
        !mentions &&
        !isBaseLLM &&
        skillFilters.length === 0 &&
        fileFilters.length === 0 &&
        answerFilters.length === 0
      ) {
        toaster.failure(
          'Please select some sources from the Apps tab and try again.'
        );

        return;
      }

      let bot: Bot | undefined;
      let sources: Sources | undefined;

      let uploadedConversationFiles: QAFile[] = uploadedQAFiles ?? [];

      if (mentions) {
        bot = bots.find((b) => b.id === mentions);
        if (bot) {
          uploadedConversationFiles = [];
          if (uploadedQAFiles) {
            for (const file of uploadedQAFiles) {
              deleteQAFile(file.id);
            }
          }
        }
      }

      if (!bot) {
        let contextualFiles: string[] = [];
        const uploadedFiles = uploadedConversationFiles.map((file) => file.id);

        contextualFiles = [...topicFiles, ...uploadedFiles];

        if (QAFileUploadStatus === UploadStatus.TRANSIT) {
          toaster.failure(
            'Please wait for the file upload to complete and try again.'
          );

          return;
        }

        sources = getSourcesFromFilters(
          isBaseLLM,
          skillFilters,
          fileFilters,
          answerFilters,
          allSkills,
          allFiles,
          allAnswers,
          contextualFiles
        );
      }

      if (sendQueryCallback) {
        sendQueryCallback();
      }

      if (!conversationId) {
        conversationId = uuidV4().replace(/-/g, '');
      }

      const args: SendQueryArgs = {
        queryText: query,
        sources,
        llm_preference: selectedLlm,
        continueTopic: !!continueConversationId,
        bot_id: bot?.id,
        uploaded_conversation_files: uploadedConversationFiles,
        conversation_id: conversationId,
        author,
        topicAccess: bot?.is_managed
          ? {
              ...topicAccess,
              isMultiplayer: true,
            }
          : topicAccess,
        onMessageAdded: (qaMessage: QAMessage) => {
          broadcastQAMessage(qaMessage);
        },
      };

      qaController.sendNewMessage(args).catch((error) => {
        logError(error);
        toaster.failure('Error processing request. Please try again.');
      });

      let overrideSurface;
      let overrideSubSurface;

      if (fromOmnibox) {
        overrideSurface = EventSurface.EXTENSION;
        overrideSubSurface = EventSubSurface.ADDRESS_BAR;
      }

      trackEvent(
        AnalyticsEvent.QAUserAskedQuestion,
        {
          user: userEmail,
          timestamp: new Date(),
          searchFilters: bot
            ? null
            : skillFilters.map(({ id, appName, displayName, isOrg }) => ({
                id,
                appName,
                displayName,
                isOrg,
              })),
          fileFilters: bot ? null : fileFilters.map(({ id }) => id),
          answerFilters: bot ? null : answerFilters.map(({ id }) => id),
          bot_id: bot?.id ?? null,
        },
        {
          question: query,
        },
        overrideSurface,
        overrideSubSurface
      );

      cleanupAfterSendingMessage();
    },
    [
      currentStage,
      mentions,
      isBaseLLM,
      skillFilters,
      fileFilters,
      answerFilters,
      sendQueryCallback,
      selectedLlm,
      continueConversationId,
      isNewTopic,
      qaController,
      userEmail,
      toaster,
      bots,
      allSkills,
      allFiles,
      allAnswers,
      conversation_id,
      uploadedQAFiles,
      topicFiles,
      deleteQAFile,
      QAFileUploadStatus,
      allMembers,
      isDashAIQuery,
      userDisplayName,
      userPhotoUrl,
      cleanupAfterSendingMessage,
      topicAccess,
      userMentions,
      userEmailMentions,
      broadcastQAMessage,
    ]
  );

  // TODO: Move the setSearchQuery logic to UITextArea
  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (
        evt.key === 'Enter' &&
        !(evt.ctrlKey || evt.metaKey || evt.shiftKey || evt.altKey)
      ) {
        evt.preventDefault();
        sendQuery(searchQuery);
      }
    },
    [searchQuery, sendQuery]
  );

  const handleNewTopicButton = useCallback(() => {
    if (disableNewTopicButton) {
      return;
    }

    trackEvent(AnalyticsEvent.QANewTopicToggled, { user: userEmail });

    if (newTopicCallback) {
      newTopicCallback();
    }

    if (continueConversationId) {
      qaController.setIsNewTopic(true);
      history.push(SidebarRoutes.JitQA);
    } else {
      qaController.setIsNewTopic(!isNewTopic);
    }
  }, [
    disableNewTopicButton,
    userEmail,
    newTopicCallback,
    continueConversationId,
    qaController,
    history,
    isNewTopic,
  ]);

  const handleTextInputChange = useCallback(
    (newValue: string) => {
      setSearchQuery(newValue);
    },
    [setSearchQuery]
  );

  const handleSaveWorkflowTemplate = useCallback(
    ({ workflowTemplate }: Partial<QAControllerEventArgs>) => {
      if (workflowTemplate) {
        trackEvent(AnalyticsEvent.ClickedOnSaveWorkflowButton, {
          promptTemplate: workflowTemplate,
          sources: getSourcesFromFilters(
            isBaseLLM,
            skillFilters,
            fileFilters,
            answerFilters,
            allSkills,
            allFiles,
            allAnswers,
            []
          ),
          preferredLlm: selectedLlm,
        });

        qaController.triggerEvent('showWorkflowModal', {
          workflowModalType: WorkflowModalTypes.CREATE,
          workflowTemplate,
          isBaseLLM,
          skillFilters,
          fileFilters,
          answerFilters,
          preferredLlm: selectedLlm,
        });
      }
    },
    [
      allAnswers,
      allFiles,
      allSkills,
      answerFilters,
      fileFilters,
      isBaseLLM,
      qaController,
      selectedLlm,
      skillFilters,
    ]
  );

  const handleViewTemplatesButton = useCallback(() => {
    trackEvent(AnalyticsEvent.OpenWorkflowsModalFromDashAi);

    qaController.triggerEvent('showWorkflowModal', {
      workflowModalType: WorkflowModalTypes.VIEW_ALL_TEMPLATES,
    });
  }, [qaController]);

  const handleStopGeneration = useCallback(() => {
    qaController.triggerEvent('stopGenerating', {});
  }, [qaController]);

  const handleSetQuery = useCallback(
    ({ query }: Partial<QAControllerEventArgs>) => {
      if (query !== undefined) {
        setSearchQuery(query);
        inputRef.current?.focus();
      }
    },
    [inputRef, setSearchQuery]
  );

  const handleSetQueryAndSources = useCallback(
    ({
      query: incomingQuery,
      isBaseLLM: incomingIsBaseLLM,
      skillFilters: incomingSkillFilters,
      fileFilters: incomingFileFilters,
      answerFilters: incomingAnswerFilters,
    }: Partial<QAControllerEventArgs>) => {
      if (
        incomingQuery !== undefined &&
        incomingIsBaseLLM !== undefined &&
        incomingSkillFilters !== undefined &&
        incomingFileFilters !== undefined &&
        incomingAnswerFilters !== undefined
      ) {
        setSearchQuery(incomingQuery);
        setIsBaseLLM(incomingIsBaseLLM);
        setSkillFilters(incomingSkillFilters);
        setFileFilters(incomingFileFilters);
        setAnswerFilters(incomingAnswerFilters);
        inputRef.current?.focus();
      }
    },
    [
      setSearchQuery,
      setIsBaseLLM,
      setSkillFilters,
      setFileFilters,
      setAnswerFilters,
      inputRef,
    ]
  );

  useEffect(() => {
    qaController.listenEvent('setQuery', handleSetQuery);
    qaController.listenEvent('setQueryAndSources', handleSetQueryAndSources);
    qaController.listenEvent('saveWorkflow', handleSaveWorkflowTemplate);

    return () => {
      qaController.off('setQuery', handleSetQuery);
      qaController.off('setQueryAndSources', handleSetQueryAndSources);
      qaController.off('saveWorkflow', handleSaveWorkflowTemplate);
    };
  }, [
    qaController,
    handleSetQuery,
    handleSetQueryAndSources,
    handleSaveWorkflowTemplate,
  ]);

  useEffect(() => {
    setDynamicPlaceholder('');
    const words = placeholderText.split(' ');
    let currentText = '';
    let index = 0;
    let intervalTime = 90;

    const addWords = () => {
      if (index < words.length) {
        currentText += words[index];
        index++;

        currentText += ' ';
        setDynamicPlaceholder(currentText);
        intervalTime -= 10;
        setTimeout(addWords, intervalTime);
      }
    };

    const timeoutId = setTimeout(addWords, intervalTime);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [placeholderText, setDynamicPlaceholder]);

  // Handle omnibox search
  useEffect(() => {
    if (queryParams[QueryParams.Search] && !isLoaded.current) {
      sendQuery(
        queryParams[QueryParams.Search],
        !!queryParams[QueryParams.Omnibox]
      );

      window.history.replaceState({}, document.title, window.location.pathname);
      isLoaded.current = true;
    }
  }, [queryParams, sendQuery]);

  return (
    <div
      className={`${className} qaTextInputAppcuesClass flex flex-col gap-2 w-full px-1 pb-3 max-w-[750px] min-h-[90px] border border-solid border-gray-30 rounded-lg shadow-flat`}
    >
      <div className="qaMentionsInputContainerClass flex px-3 pt-3">
        {multiplayerEnabled ? (
          <QAMentionsInput
            allBots={bots}
            allBotsLoading={botsLoading}
            allMembers={allMembers}
            allMembersLoading={allMembersLoading}
            autofocus={autofocus}
            dynamicPlaceholder={dynamicPlaceholder}
            fetchMembersByQuery={fetchMembersByQuery}
            handleTextInputChange={handleTextInputChange}
            inputRef={inputRef}
            mentions={mentions}
            onKeyDown={onKeyDown}
            searchQuery={searchQuery}
            selectedBotMention={mentions}
            selectedUserMentions={userMentions}
            setMentions={setMentions}
            setUserMentions={setUserMentions}
            shouldShowDashworksBot={Boolean(
              !isNewTopic && topicAccess.isMultiplayer
            )}
            showPopupFromBottom={showPopupFromBottom}
          />
        ) : (
          <QABotsMentionsInput
            autofocus={autofocus}
            bots={bots}
            dynamicPlaceholder={dynamicPlaceholder}
            handleTextInputChange={handleTextInputChange}
            inputRef={inputRef}
            mentions={mentions}
            onKeyDown={onKeyDown}
            searchQuery={searchQuery}
            setMentions={setMentions}
            showPopupFromBottom={showPopupFromBottom}
          />
        )}
      </div>
      {!isBotMentioned && uploadedQAFiles && uploadedQAFiles.length > 0 && (
        <div className="pt-3 mx-3">
          <QAAttachedFiles canBeDeleted files={uploadedQAFiles} />
        </div>
      )}
      <div className="flex justify-between items-center sm:px-3 px-1">
        <div className="flex items-center sm:gap-2 gap-1">
          {!hideNewTopicButton && (
            <div className="flex">
              <UITooltip
                title={
                  disableNewTopicButton
                    ? disableNewTopicButtonReason
                    : // eslint-disable-next-line unicorn/no-nested-ternary
                    isNewTopic
                    ? 'Resume previous topic'
                    : 'Start new topic'
                }
              >
                <div
                  className={`hover:bg-cloud-10 rounded-md ${
                    disableNewTopicButton
                      ? 'cursor-not-allowed pr-1'
                      : 'cursor-pointer p-2'
                  }`}
                  onClick={handleNewTopicButton}
                >
                  {disableNewTopicButton ? (
                    <MapsUgcOutlinedIcon
                      sx={{ display: 'block', fontSize: 16, opacity: 0.5 }}
                    />
                  ) : // eslint-disable-next-line unicorn/no-nested-ternary
                  isNewTopic ? (
                    <ReplayOutlinedIcon
                      sx={{ display: 'block', fontSize: 16 }}
                    />
                  ) : (
                    <MapsUgcOutlinedIcon
                      sx={{ display: 'block', fontSize: 16 }}
                    />
                  )}
                </div>
              </UITooltip>
            </div>
          )}
          <SourcesFilterSwitch
            allAnswers={allAnswers}
            allFiles={allFiles}
            allSkills={allSkills}
            allSkillsUserApps={allSkillsUserApps}
            answerFilters={answerFilters}
            bot={bots.find((b) => b.id === mentions)}
            botsEnabledApps={botsEnabledApps}
            botsEnabledUserApps={botsEnabledUserApps}
            fileFilters={fileFilters}
            isBaseLLM={isBaseLLM}
            isDisabled={isSelectModesDisabled}
            setAnswerFilters={setAnswerFilters}
            setFileFilters={setFileFilters}
            setIsBaseLLM={setIsBaseLLM}
            setSkillFilters={setSkillFilters}
            skillFilters={skillFilters}
          />
          <AttachFile
            disabled={QAFileUploadStatus === UploadStatus.TRANSIT}
            onFilesAttached={(files) => {
              uploadFiles(files);
            }}
          >
            <div>
              <UITooltip
                title={
                  hasConversationFiles ? 'Attach more files' : 'Attach files'
                }
              >
                <div
                  className={classNames(
                    'flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border',
                    {
                      ' bg-gray-20':
                        QAFileUploadStatus === UploadStatus.TRANSIT,
                    }
                  )}
                >
                  <UIIcon
                    name={
                      QAFileUploadStatus === UploadStatus.TRANSIT
                        ? 'spinner-animated'
                        : 'attachment'
                    }
                    size={16}
                  />
                </div>
              </UITooltip>
            </div>
          </AttachFile>
          {multiplayerEnabled ? (
            <MentionsSelectButtonDashAi
              bots={bots}
              botsLoading={botsLoading}
              disabled={!!mentions}
              isBotSelected={!!mentions}
              isMemberSelected={userMentions.length > 0}
              setSelectedBot={(bot) => {
                setSearchQuery(
                  (prev) => `${prev} @{{${bot.bot_name}}}{{${bot.id}}} `
                );

                setMentions(bot.id);
                trackEvent(AnalyticsEvent.MentionedBot, { bot_id: bot.id });
              }}
              setSelectedMember={(member) => {
                setSearchQuery(
                  (prev) =>
                    `${prev} @{{${member.name ?? member.email}}}{{${
                      member.user_id
                    }}} `
                );

                setUserMentions((prev) => [...prev, member.user_id]);
                trackEvent(AnalyticsEvent.MentionedUser, {
                  user_id: member.user_id,
                });
              }}
            />
          ) : (
            <BotsSelectButtonDashAi
              bots={bots}
              botsLoading={botsLoading}
              disabled={!!mentions || userMentions.length > 0}
              setSelectedBot={(bot) => {
                setSearchQuery(
                  (prev) => `${prev} @{{${bot.bot_name}}}{{${bot.id}}} `
                );

                setMentions(bot.id);
                trackEvent(AnalyticsEvent.MentionedBot, { bot_id: bot.id });
              }}
            />
          )}
          <LLMSelectPopoverBtn
            bot={bots.find((b) => b.id === mentions)}
            disabled={isSelectModesDisabled}
            selectedLlm={selectedLlm}
            setOpenPaywallModal={setOpenPaywallModal}
            setSelectedLlm={setSelectedLlm}
          />
          {!continueConversationId && (
            <UITooltip title="View workflows">
              <div
                className="flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border"
                onClick={handleViewTemplatesButton}
              >
                <UIIcon name="bolt" style={{ display: 'block' }} />
              </div>
            </UITooltip>
          )}
          {searchQuery.length > MAX_QUERY_LENGTH && !isBaseLLM && (
            <UITooltip
              title={`Dash AI works best when your query is limited to ${MAX_QUERY_LENGTH} characters.`}
            >
              <div className="hidden sm:block text-sm text-gray-50">
                <span className="text-mahogany-20">{searchQuery.length}</span> /{' '}
                {MAX_QUERY_LENGTH} chars
              </div>
            </UITooltip>
          )}
        </div>
        <div className="ml-1">
          {currentStage === QAStages.WAIT_USER_INPUT ? (
            <button
              className="bg-transparent m-0 p-0 border-0 outline-0"
              disabled={QAFileUploadStatus === UploadStatus.TRANSIT}
              type="button"
            >
              <UIIconButton
                name="send"
                onClick={() => {
                  sendQuery(searchQuery);
                }}
                type="ui"
              />
            </button>
          ) : // eslint-disable-next-line unicorn/no-nested-ternary
          hideStopAnswerGenerationButton ? null : (
            <UIIconButton
              name="stop-circle"
              onClick={handleStopGeneration}
              size={24}
              tooltip="Stop generating answer"
              type="ui"
            />
          )}
        </div>
      </div>
    </div>
  );
};
