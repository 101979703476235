import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useState } from 'react';
import { CreatorDetails } from '../../models/Bots';
import { useToaster } from '../../scripts/hooks';
import { useMembers } from '../../scripts/hooks/members';
import { UserImage } from '../controls/UserImage/UserImage';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../controls/ui/UITextbox/UITextbox';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../shadcn/lib/components/popover';

interface BotAdminsSelectProps {
  admins: CreatorDetails[];
  setAdmins: React.Dispatch<React.SetStateAction<CreatorDetails[]>>;
  hasEditPermissions: boolean;
}

export const BotAdminsSelect: FC<BotAdminsSelectProps> = ({
  admins,
  setAdmins,
  hasEditPermissions,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  const [query, setQuery] = useState('');
  const { members, fetchMembersByQuery } = useMembers();

  const toaster = useToaster();

  const handleOpenSelect = useCallback(() => {
    setOpenSelect((prev) => !prev);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMembers = useCallback(
    debounce((value: string) => {
      fetchMembersByQuery(value);
    }, 200),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      debouncedFetchMembers(value);
    },
    [debouncedFetchMembers]
  );

  return (
    <Popover onOpenChange={handleOpenSelect} open={openSelect}>
      <PopoverTrigger asChild>
        <div className="w-fit">
          <UITooltip
            title={`${hasEditPermissions ? 'Select' : 'View'} Bot Admins`}
          >
            <div
              className={classNames(
                'flex items-center p-1 rounded-md border-gray-30 border-solid border select-none',
                {
                  'cursor-pointer hover:bg-cloud-10': hasEditPermissions,
                  'bg-cloud-10': !hasEditPermissions,
                }
              )}
            >
              {admins.slice(0, 4).map(({ icon, display_name, email }) => {
                return (
                  <UserImage
                    displayName={display_name}
                    imageUrl={icon}
                    key={email}
                    size={20}
                  />
                );
              })}
              <UIIcon
                className="ml-1 sm:inline hidden hover:pt-1"
                name="arrow-down"
              />
            </div>
          </UITooltip>
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-[1000] bg-white p-0 mr-2 sm:mr-0">
        {hasEditPermissions && (
          <>
            <UITextbox
              autoFocus
              onChange={handleSearch}
              placeholder="Search members"
              value={query}
            />
            {query.length > 0 &&
              members.slice(0, 5).map(({ icon, name, email }) => {
                const isMemberAlreadyAdmin = admins.some(
                  (admin) => admin.email === email
                );

                if (isMemberAlreadyAdmin) {
                  return null;
                }

                return (
                  <div
                    className="flex items-center gap-2 p-2 cursor-pointer hover:bg-cloud-10"
                    key={email}
                    onClick={() => {
                      setAdmins((prev) => [
                        ...prev,
                        {
                          icon,
                          display_name: name,
                          email,
                        },
                      ]);
                    }}
                  >
                    <UserImage displayName={name} imageUrl={icon} size={20} />
                    <div>{name}</div>
                  </div>
                );
              })}
          </>
        )}
        {admins.map(({ icon, display_name, email }) => {
          return (
            <div
              className={classNames('flex items-center gap-2 p-2', {
                'cursor-pointer hover:bg-cloud-10': hasEditPermissions,
              })}
              key={email}
              onClick={() => {
                if (!hasEditPermissions) {
                  return;
                }

                if (admins.length > 1) {
                  setAdmins((prev) => prev.filter((a) => a.email !== email));
                } else {
                  toaster.failure('At least one Bot Admin required');
                }
              }}
            >
              <UserImage displayName={display_name} imageUrl={icon} size={20} />
              <div>{display_name}</div>
              <UIIcon name="check" />
            </div>
          );
        })}
      </PopoverContent>
    </Popover>
  );
};
