import React, { FC, useCallback, useState } from 'react';
import botsImage from '../../assets/images/bots.png';
import {
  EnterpriseIcon,
  PaywallModal,
} from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { BotsModal } from '../../components/bots/BotsModal';
import { Loading } from '../../components/controls/Loading/Loading';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { Feature, useFeatureAccess } from '../../hooks/subscriptionHooks';
import { Bot } from '../../models/Bots';
import { useBoolState } from '../../scripts/hooks';
import { useBots } from '../../scripts/hooks/bots';
import { useRecommendedSlackbotChannels } from '../../scripts/hooks/recommendedSlackbotChannels';
import { useSlackbotChannels } from '../../scripts/hooks/slackbotChannels';
import { BotsTable } from './BotsTable';

export const BotsPage: FC = () => {
  const [currentBot, setCurrentBot] = useState<Bot | undefined>(undefined);
  const [showCreateBotModal, setShowCreateBotModal] = useState(false);

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  const hasCustomBotsFeature = useFeatureAccess(Feature.CUSTOM_BOTS);

  const {
    bots,
    botsEnabledApps,
    botsEnabledUserApps,
    hasRecommendedChannels,
    loading: botsLoading,
    createBot,
    deleteBot,
    updateBot,
  } = useBots();

  const {
    slackbotChannels,
    loading: slackbotChannelsLoading,
    updateSlackbotChannel,
  } = useSlackbotChannels();

  const {
    recommendedSlackbotChannels,
    loading: recommendedSlackbotChannelsLoading,
    getRecommendedSlackbotChannels,
    autoJoinRecommendedSlackbotChannel,
  } = useRecommendedSlackbotChannels();

  const closeBotsModal = useCallback(() => {
    setCurrentBot(undefined);
    setShowCreateBotModal(false);
  }, []);

  const handleCreateBotClick = useCallback(() => {
    if (!hasCustomBotsFeature) {
      setOpenPaywallModal();
      return;
    }

    setCurrentBot(undefined);
    setShowCreateBotModal(true);
  }, [hasCustomBotsFeature, setOpenPaywallModal]);

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl">
      <div className="flex gap-2 items-center">
        <UIIcon name="bot" size={32} />
        <h2 className="text-2xl m-0 font-bold">Bots</h2>
      </div>

      {botsLoading ? (
        <Loading />
      ) : // eslint-disable-next-line unicorn/no-nested-ternary
      bots.length === 0 ? (
        <div className="flex flex-col gap-10">
          <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
            <div className="p-2 bg-white rounded-full">
              <UIIcon name="info" size={24} />
            </div>
            <div>
              Create Bots for different use cases using tailored knowledge
              sources and instructions. Tag them on the web app with "@Name" or
              invite them to a Slack channel with "/invite @Dashworks".
            </div>
          </div>
          <div className="max-w-3xl flex flex-col gap-10">
            <img className="w-full" src={botsImage} />
            <div className="flex flex-col gap-1">
              <div className="font-semibold">Add bot app connections</div>
              <div>
                Bots use the bot app connections to find answers to questions
                asked in Dashworks. An admin is required to complete this step.
                Click{' '}
                <a
                  href="https://support.dashworks.ai/bots/setup-bots"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>{' '}
                to learn more.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-10 max-sm:p-4">
            <div className="p-2 bg-white rounded-full">
              <UIIcon name="info" size={24} />
            </div>
            <div>
              Create Bots for different use cases using tailored knowledge
              sources and instructions. Tag them on the web app with "@Name" or
              invite them to a Slack channel with "/invite @Dashworks".
            </div>
            <UIButton
              onClick={handleCreateBotClick}
              type={hasCustomBotsFeature ? 'primary' : 'secondary'}
            >
              {!hasCustomBotsFeature && <EnterpriseIcon />}
              Create Bot
            </UIButton>
          </div>

          <BotsTable
            autoJoinRecommendedSlackbotChannel={
              autoJoinRecommendedSlackbotChannel
            }
            bots={bots}
            getRecommendedSlackbotChannels={getRecommendedSlackbotChannels}
            recommendedSlackbotChannels={recommendedSlackbotChannels}
            recommendedSlackbotChannelsLoading={
              recommendedSlackbotChannelsLoading
            }
            setCurrentBot={setCurrentBot}
            showRecommended={hasRecommendedChannels}
            slackbotChannels={slackbotChannels}
            slackbotChannelsLoading={slackbotChannelsLoading}
            updateSlackbotChannel={updateSlackbotChannel}
          />
        </div>
      )}

      {(currentBot !== undefined || showCreateBotModal) && (
        <BotsModal
          bot={currentBot}
          botsEnabledApps={botsEnabledApps}
          botsEnabledUserApps={botsEnabledUserApps}
          closeModal={closeBotsModal}
          createBot={createBot}
          deleteBot={deleteBot}
          setOpenPaywallModal={setOpenPaywallModal}
          slackbotChannels={slackbotChannels}
          type={showCreateBotModal ? 'create' : 'edit'}
          updateBot={updateBot}
        />
      )}
      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};
