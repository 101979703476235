/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TOPIC_LINK_COPIED_SUCCESS_MESSAGE } from '../../../constants';
import { CreatorDetails, ManagedBotAdminsResponse } from '../../../models/Bots';
import {
  ConversationMember,
  ConversationMemberRole,
  QAMessage,
  QATopicAccess,
  TopicVisibility,
} from '../../../models/QAmodels';
import { useQAController } from '../../../scripts/QAController';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { useUserSafe } from '../../../scripts/hooks';
import { useToaster } from '../../../scripts/hooks/toast';
import { logError, uniqBy } from '../../../scripts/utils';
import { Loading } from '../../controls/Loading/Loading';
import { RadioList } from '../../controls/RadioList/RadioList';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UserProfileIcon } from '../../general/UserProfile/UserProfile';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

interface TopicSharePopoverProps {
  conversationId?: string;
  showDivider?: boolean;
  topicAccess: QATopicAccess;
  messages: QAMessage[];
}

const commonCardClassNames = 'flex gap-2 mb-2 items-center';

export const TopicSharePopover: FC<TopicSharePopoverProps> = ({
  conversationId,
  showDivider,
  topicAccess,
  messages,
}) => {
  const toaster = useToaster();
  const {
    orgByOrgId: {
      preferences: { logo },
      name: orgName,
    },
  } = useUserSafe();

  const [members, setMembers] = useState<ConversationMember[]>([]);
  const [managedBotAdmins, setManagedBotAdmins] =
    useState<ManagedBotAdminsResponse | null>(null);

  const [loading, setLoading] = useState(false);
  const [showConversationMembersPopover, setShowConversationMembersPopover] =
    useState(false);

  const [showOrgAccessPopover, setShowOrgAccessPopover] = useState(false);

  const qaController = useQAController();

  const newlyAddedConversationMembers =
    qaController.useNewlyAddedConversationMembers();

  const allMembers: ConversationMember[] = useMemo(() => {
    return uniqBy(
      [
        ...members,
        ...newlyAddedConversationMembers.filter(
          (member) => member.conversation_id === conversationId
        ),
      ],
      'email'
    );
  }, [members, newlyAddedConversationMembers, conversationId]);

  useEffect(() => {
    const newMembers: ConversationMember[] = messages
      .filter((msg) => msg.author)
      .map((msg) => ({
        ...msg.author!,
        display_name: msg.author!.display_name ?? '',
        role: ConversationMemberRole.EDITOR,
        conversation_id: msg.conversation_id,
      }));

    setMembers((prev) => {
      const hasNewMembers = newMembers.some(
        (newMember) =>
          !prev.some((prevMember) => prevMember.email === newMember.email)
      );

      if (!hasNewMembers) return prev;

      return uniqBy([...prev, ...newMembers], 'email');
    });
  }, [messages]);

  const loadMembers = useCallback(async () => {
    if (!conversationId) {
      toaster.failure('Failed to load members');
      return;
    }

    if (!topicAccess.isMultiplayer) return;

    try {
      setLoading(true);
      const result = await invokeFastApi<{
        members: ConversationMember[];
        managed_bot_admins: ManagedBotAdminsResponse;
      }>({
        path: `/topics/topic/members/${conversationId}`,
        method: 'GET',
      });

      setMembers((prevMembers) =>
        uniqBy([...prevMembers, ...result.members], 'email')
      );

      setManagedBotAdmins(result.managed_bot_admins);
    } catch {
      toaster.failure('Failed to load members');
    } finally {
      setLoading(false);
    }
  }, [conversationId, toaster, topicAccess.isMultiplayer]);

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const commonAvatarClassNames = 'rounded-[50%] w-8 h-8';
  const iconContainerClassNames = `${commonAvatarClassNames} min-w-[32px] min-h-[32px] bg-gray-20 overflow-hidden relative`;
  const centerClassName = `absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`;

  return (
    <Popover
      onOpenChange={setShowConversationMembersPopover}
      open={showConversationMembersPopover}
    >
      <PopoverTrigger asChild>
        <div className="flex gap-1">
          <div
            className="flex p-0.5 rounded hover:bg-cloud-15"
            onClick={async () => {
              setShowConversationMembersPopover(
                !showConversationMembersPopover
              );

              if (
                !showConversationMembersPopover &&
                conversationId &&
                !topicAccess.isMultiplayer &&
                !topicAccess.isOrgWide
              ) {
                try {
                  const sharableLink = `${window.location.origin}/topic/${conversationId}`;
                  await navigator.clipboard.writeText(sharableLink);
                  toaster.success(TOPIC_LINK_COPIED_SUCCESS_MESSAGE);
                } catch (error) {
                  logError(error);
                }
              }
            }}
          >
            <UIIcon
              name={
                topicAccess.isOrgWide
                  ? 'company'
                  : // eslint-disable-next-line unicorn/no-nested-ternary
                  topicAccess.isMultiplayer
                  ? 'group'
                  : 'link'
              }
              tooltip="Share settings"
            />
          </div>
          {showDivider && (
            <div className="text-cloud-20 text-sm hidden sm:block">|</div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-10 bg-white sm:w-[424px] w-[250px] custom-scrollbar overflow-y-auto max-h-[450px]">
        <div className="font-semibold mb-2">
          {topicAccess.isMultiplayer || topicAccess.isOrgWide
            ? 'You shared this topic'
            : 'This is a private topic'}
        </div>

        {topicAccess.isMultiplayer ? (
          <>
            <div className={commonCardClassNames}>
              <div className={iconContainerClassNames}>
                <UIIcon className={centerClassName} name="messages" />
              </div>{' '}
              <div> Just type to message members added to this topic</div>
            </div>

            <div className={commonCardClassNames}>
              <div className={iconContainerClassNames}>
                <UIIcon className={centerClassName} name="at-sign" stroke />
              </div>{' '}
              <div>
                Type <span>@Dashworks</span> to ask Dashworks a question
              </div>
            </div>

            <div className={commonCardClassNames}>
              <div className={iconContainerClassNames}>
                <UIIcon className={centerClassName} name="group" />
              </div>
              <div>Invite more members to this topic by adding @Name</div>
            </div>
          </>
        ) : (
          <div className={commonCardClassNames}>
            <div className={iconContainerClassNames}>
              <UIIcon className={centerClassName} name="group" />
            </div>
            <div>Invite more members to this topic by adding @Name</div>
          </div>
        )}

        <div className="font-semibold mb-2 mt-4">Organization</div>
        <div className={`${commonCardClassNames} justify-between mb-4`}>
          <div className="flex gap-2 items-center">
            <div className="flex">
              <UserProfileIcon icon={logo} name={orgName} />
            </div>
            <div>Everyone at {orgName}</div>
          </div>
          <div>
            <Popover
              modal
              onOpenChange={setShowOrgAccessPopover}
              open={showOrgAccessPopover}
            >
              <PopoverTrigger asChild>
                <div className="flex items-center cursor-pointer">
                  <div>{topicAccess.isOrgWide ? 'Can read' : 'No access'}</div>
                  <UIIcon
                    className="sm:inline hidden hover:pt-1"
                    name="arrow-down"
                    size={18}
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent className="p-0 m-4 mt-0 text-sm cursor-pointer z-[250] max-w-[150px]">
                <RadioList<string>
                  listItemClass="hover:cursor-pointer hover:bg-cloud-10"
                  onChange={async (val) => {
                    if (!conversationId) return;

                    setShowOrgAccessPopover(false);

                    const newVisibility =
                      val === 'read'
                        ? TopicVisibility.ORG
                        : // eslint-disable-next-line unicorn/no-nested-ternary
                        topicAccess.isMultiplayer
                        ? TopicVisibility.SHARED
                        : TopicVisibility.PRIVATE;

                    await qaController.updateConversationVisibility(
                      conversationId,
                      newVisibility,
                      topicAccess
                    );
                  }}
                  options={[
                    { value: 'read', displayName: 'Can read' },
                    { value: 'none', displayName: 'No access' },
                  ]}
                  selected={topicAccess.isOrgWide ? 'read' : 'none'}
                  setPaywallModalOpen={() => {
                    // empty
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>

        {topicAccess.isMultiplayer && (
          <div>
            <div className="flex justify-between">
              <div className="font-semibold mb-2">Members</div>

              {allMembers.length > 0 && loading && (
                <UIIcon name="spinner-animated" size={16} />
              )}
            </div>
            {allMembers.length === 0 && loading && <Loading />}
            <MembersList members={allMembers} />

            {managedBotAdmins &&
              managedBotAdmins.length > 0 &&
              managedBotAdmins.map(([bot, botAdmins]) => (
                <div key={bot.id}>
                  <div className="font-semibold mb-2 mt-4">
                    {bot.icon} {bot.bot_name} Admins
                  </div>
                  <MembersList members={botAdmins} />
                </div>
              ))}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

const MembersList = ({
  members,
}: {
  members: CreatorDetails[];
}): JSX.Element => {
  return (
    <div>
      {members.map((member) => (
        <div
          className={`${commonCardClassNames} justify-between`}
          key={member.email}
        >
          <div className="flex gap-2 items-center mb-0">
            <div className="flex">
              <UserProfileIcon
                email={member.email}
                icon={member.icon}
                name={member.display_name}
              />
            </div>
            <div>{member.display_name}</div>
          </div>
          <div>Can edit</div>
        </div>
      ))}
    </div>
  );
};
