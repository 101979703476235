import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Feedback } from '../../models/Bots';
import { UIIconButton } from '../controls/ui/UIIconButton/UIIconButton';
import { TableCell, TableRow } from '../shadcn/lib/components/table';

interface FeedbackListItemProps {
  feedback: Feedback;
  onEdit: (updatedFeedback: string) => void;
  onDelete: () => void;
}

export const FeedbackListItem: FC<FeedbackListItemProps> = ({
  feedback: { processed_feedback },
  onEdit,
  onDelete,
}) => {
  const [newProcessedFeedback, setNewProcessedFeedback] =
    useState(processed_feedback);

  const [isEditing, setIsEditing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const handleUpdateProcessedFeedback = useCallback(() => {
    setIsEditing(false);

    if (processed_feedback === newProcessedFeedback) return;

    if (newProcessedFeedback === '' && divRef.current) {
      setNewProcessedFeedback(processed_feedback);
      divRef.current.textContent = processed_feedback;
      return;
    }

    onEdit(newProcessedFeedback);
  }, [processed_feedback, newProcessedFeedback, onEdit]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (divRef.current) {
          divRef.current.blur();
        }
      }
    },
    []
  );

  useEffect(() => {
    if (isEditing && divRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(divRef.current);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
      divRef.current.focus();
    }
  }, [isEditing]);

  return (
    <TableRow className="font-medium w-full">
      <TableCell className="w-5/6">
        <div
          className={`outline-none hidden sm:block  ${
            isEditing ? 'cursor-text' : 'cursor-default'
          }`}
          contentEditable={isEditing}
          onBlur={handleUpdateProcessedFeedback}
          onInput={(e) => {
            setNewProcessedFeedback(
              e.currentTarget.textContent ?? processed_feedback
            );
          }}
          onKeyDown={handleKeyDown}
          ref={divRef}
          suppressContentEditableWarning
        >
          {processed_feedback}
        </div>
      </TableCell>
      <TableCell className="w-1/12">
        <UIIconButton
          className="focus:outline-none"
          name="pencil"
          onClick={() => {
            setIsEditing(true);
          }}
        />
      </TableCell>
      <TableCell className="w-1/12">
        <UIIconButton
          className="focus:outline-none"
          name="trash-can"
          onClick={onDelete}
        />
      </TableCell>
    </TableRow>
  );
};
