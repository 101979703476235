import { makeLazyComponent } from '../../scripts/utils';

export const LazyNotificationsPreferencesPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "user-notification-preferences-page" */ './NotificationsPreferences'
      )
    ).NotificationsPreferencesPage
);
