import { Bot } from '../../models/Bots';
import { UserApp } from '../../models/User';

export const DEFAULT_BOTS_STATE: BotsState = {
  bots: [],
  enabledUserApps: [],
  hasRecommendedChannels: false,
};

export interface BotsState {
  bots: Bot[];
  enabledUserApps: UserApp[];
  hasRecommendedChannels: boolean;
}
