import React, { FC, ReactNode, useMemo } from 'react';
import { Loading } from '../../../components/controls/Loading/Loading';
import { UIButton } from '../../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../components/controls/ui/UIIcon/UIIcon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/shadcn/lib/components/table';
import { trackEvent } from '../../../extra/sharedMethods';
import { RecommendedSlackbotChannel } from '../../../models/Bots';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useUserSafe } from '../../../scripts/hooks';
import { AutoJoinChannelBody } from '../../../scripts/hooks/recommendedSlackbotChannels';

interface RecommendedSlackbotChannelsTabProps {
  recommendedSlackbotChannels: RecommendedSlackbotChannel[];
  loading: boolean;
  getRecommendedSlackbotChannels: () => Promise<void>;
  autoJoinRecommendedSlackbotChannel: (
    body: AutoJoinChannelBody,
    autoJoinFailureToastMessage: ReactNode
  ) => Promise<void>;
}

export const RecommendedSlackbotChannelsTab: FC<
  RecommendedSlackbotChannelsTabProps
> = ({
  recommendedSlackbotChannels,
  loading,
  getRecommendedSlackbotChannels,
  autoJoinRecommendedSlackbotChannel,
}) => {
  const {
    admin,
    usersApps: { nodes: userApps },
  } = useUserSafe();

  const userSlackApps = useMemo(
    () =>
      userApps.filter((app) => app.appName === 'slack' && app.statusCode === 1),
    [userApps]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-4/5">Name</TableHead>
          <TableHead className="w-1/5" />
        </TableRow>
      </TableHeader>
      <TableBody>
        {recommendedSlackbotChannels.map(
          ({ id, org_id, team_id, channel_id, channel_name, channel_link }) => (
            <TableRow key={id}>
              <TableCell>
                <div className="flex items-center gap-2">
                  <UIIcon name="slack" size={24} type="apps" />
                  {channel_name}
                </div>
              </TableCell>
              <TableCell>
                {admin &&
                userSlackApps.some((slack) => slack.workspaceId === team_id) ? (
                  <UIButton
                    onClick={async () => {
                      const userSlackAppWithSameSlackWorkspace =
                        userSlackApps.find(
                          (slack) => slack.workspaceId === team_id
                        );

                      await autoJoinRecommendedSlackbotChannel(
                        {
                          user_slack_app_id:
                            userSlackAppWithSameSlackWorkspace!.id,
                          org_id,
                          team_id,
                          channel_id,
                        },
                        <AutoJoinFailureToastMessage />
                      );

                      setTimeout(() => {
                        window.open(channel_link);
                      }, 1500);

                      setTimeout(getRecommendedSlackbotChannels, 11_000);

                      trackEvent(
                        AnalyticsEvent.RecommendedSlackbotChannelCTAClicked,
                        {
                          title: 'Add to Channel',
                          team_id,
                          channel_id,
                          channel_name,
                        }
                      );
                    }}
                    type="secondary"
                  >
                    Add to Channel
                  </UIButton>
                ) : (
                  <UIButton
                    onClick={() => {
                      window.open(channel_link);

                      trackEvent(
                        AnalyticsEvent.RecommendedSlackbotChannelCTAClicked,
                        {
                          title: 'Open Channel',
                          team_id,
                          channel_id,
                          channel_name,
                        }
                      );
                    }}
                    type="secondary"
                  >
                    Open Channel
                  </UIButton>
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

const AutoJoinFailureToastMessage: FC = () => (
  <div className="w-80 flex flex-col gap-1">
    <strong>Failed to auto-join channel</strong>
    <div className="text-cloud-40 text-xs">
      You can manually add the slackbot by typing "/invite @Dashworks" in your
      slack channel.
    </div>
  </div>
);
