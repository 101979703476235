import { useEffect, useState } from 'react';
import { SlackbotChannel } from '../../models/Bots';
import { invokeFastApi } from '../apis/fastapi';
import { logError } from '../utils';
import { useToaster } from './toast';

export interface UpdateSlackbotChannelParams {
  team_id: string;
  channel_id: string;
  is_autorespond?: boolean;
  respond_only_if_answer_found?: boolean;
  bot_id?: string;
  trigger_only_when_tagged?: boolean;
  auto_reply_threshold?: number;
  acknowledge_question?: boolean;
  create_draft?: boolean;
  fallback_message?: string;
}

interface UpdateSlackbotChannelBody {
  is_autorespond?: boolean;
  respond_only_if_answer_found?: boolean;
  bot_id?: string;
  trigger_only_when_tagged?: boolean;
  auto_reply_threshold?: number;
  acknowledge_question?: boolean;
  create_draft?: boolean;
  fallback_message?: string;
}

interface SlackbotChannelsResponse {
  slackbotChannels: SlackbotChannel[];
  loading: boolean;
  error: boolean;
  getSlackbotChannels: () => Promise<void>;
  updateSlackbotChannel: (params: UpdateSlackbotChannelParams) => Promise<void>;
}

export const useSlackbotChannels = (): SlackbotChannelsResponse => {
  const [slackbotChannels, setSlackbotChannels] = useState<SlackbotChannel[]>(
    []
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const toaster = useToaster();

  const getSlackbotChannels = async () => {
    setSlackbotChannels([]);
    setLoading(true);
    setError(false);

    try {
      const { slack_channels } = await invokeFastApi<{
        slack_channels: SlackbotChannel[];
      }>({
        path: '/bots/slack/channels',
        method: 'GET',
      });

      setSlackbotChannels(slack_channels);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
      toaster.failure('Failed to fetch slackbot channels');
    }

    setLoading(false);
  };

  const updateSlackbotChannel = async ({
    team_id,
    channel_id,
    is_autorespond,
    respond_only_if_answer_found,
    bot_id,
    trigger_only_when_tagged,
    auto_reply_threshold,
    acknowledge_question,
    create_draft,
    fallback_message,
  }: UpdateSlackbotChannelParams) => {
    const bodyData: UpdateSlackbotChannelBody = {};

    if (bot_id !== undefined) {
      bodyData.bot_id = bot_id;
    }

    if (trigger_only_when_tagged !== undefined) {
      bodyData.trigger_only_when_tagged = trigger_only_when_tagged;
    }

    if (auto_reply_threshold !== undefined) {
      bodyData.auto_reply_threshold = auto_reply_threshold;
      bodyData.respond_only_if_answer_found = auto_reply_threshold > 0;
      bodyData.is_autorespond = auto_reply_threshold > 0;
    }

    if (acknowledge_question !== undefined) {
      bodyData.acknowledge_question = acknowledge_question;
    }

    if (create_draft !== undefined) {
      bodyData.create_draft = create_draft;
    }

    if (fallback_message !== undefined) {
      bodyData.fallback_message = fallback_message;
    }

    try {
      await invokeFastApi({
        // eslint-disable-next-line @cspell/spellchecker
        path: `/bots/slack/channels/${team_id}/${channel_id}`,
        method: 'PATCH',
        body: bodyData,
      });

      setSlackbotChannels((prevChannels) =>
        prevChannels.map((channel) =>
          channel.team_id === team_id && channel.channel_id === channel_id
            ? {
                ...channel,
                is_autorespond: is_autorespond ?? channel.is_autorespond,
                respond_only_if_answer_found:
                  respond_only_if_answer_found ??
                  channel.respond_only_if_answer_found,
                bot_id: bot_id ?? channel.bot_id,
                trigger_only_when_tagged:
                  trigger_only_when_tagged ?? channel.trigger_only_when_tagged,
                auto_reply_threshold:
                  auto_reply_threshold ?? channel.auto_reply_threshold,
                acknowledge_question:
                  acknowledge_question ?? channel.acknowledge_question,
                create_draft: create_draft ?? channel.create_draft,
                fallback_message: fallback_message ?? channel.fallback_message,
              }
            : channel
        )
      );

      toaster.success('Slack channel updated successfully');
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to update slack channel');
    }
  };

  useEffect(() => {
    getSlackbotChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    slackbotChannels,
    loading,
    error,
    getSlackbotChannels,
    updateSlackbotChannel,
  };
};
