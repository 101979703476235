import { Sources, SupportedLlm } from './User';

export enum BotVisibility {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
  ORG_WIDE = 'ORG_WIDE',
  PUBLIC = 'PUBLIC',
}

export interface Feedback {
  id: string;
  raw_feedback: string;
  processed_feedback: string;
  created_at: number;
  status: 'ACTIVE' | 'INACTIVE';
  user_id: string;
  org_id: string;
}

export interface CreatorDetails {
  icon?: string;
  display_name?: string;
  email: string;
}

export interface BotMinimal {
  id: string;
  icon: string;
  bot_name: string;
  description: string | null;
}

export interface Bot extends BotMinimal {
  org_id: string;
  is_default: boolean;
  is_managed: boolean;
  sources: Sources;
  preferred_llm: SupportedLlm | null;
  visibility: BotVisibility;
  custom_instructions: string;
  created_at: number;
  modified_at: number;
  author: CreatorDetails;
  user_id?: string;
  admins: CreatorDetails[];
  feedbacks?: Feedback[];
  slackChannelIds?: string[];
}

export interface SlackbotChannel {
  id: string;
  org_id: string;
  bot_id: string;
  team_id: string;
  channel_id: string;
  channel_name: string;
  creator_app_user_id: string;
  is_autorespond: boolean;
  respond_only_if_answer_found: boolean;
  trigger_only_when_tagged: boolean;
  acknowledge_question: boolean;
  auto_reply_threshold: number;
  create_draft: boolean;
  fallback_message: string;
  created_at: number;
  modified_at: number;
  author: CreatorDetails;
}

export interface RecommendedSlackbotChannel {
  id: string;
  org_id: string;
  slack_app_id: number;
  team_id: string;
  channel_id: string;
  channel_name: string;
  channel_link: string;
  created_at: number;
  modified_at: number;
}

export type ManagedBotAdminsResponse = [BotMinimal, CreatorDetails[]][];
